import { createSender } from '@resource/resource';

// deleteResource
import { prefixAPI } from '@resource/api';
// import { prefixLanguage } from '@utils/urls'
// import {
//   simpleTransformer,
// } from '@resource/transformer'

const CONTACT_US_URL = prefixAPI('feedback/create-appeal/');
const SUBSCRIBE_SUBSCRIBE_URL = prefixAPI('subscribe/create/');
const SUBSCRIBE_UNSUBSCRIBE_URL = prefixAPI('subscribe/delete/');

export const contactUsResource = createSender(CONTACT_US_URL);
export const subscribeResource = createSender(SUBSCRIBE_SUBSCRIBE_URL);
export const unsubscribeResource = createSender(SUBSCRIBE_UNSUBSCRIBE_URL);

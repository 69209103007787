<template lang="pug">
include /mixins
#content-parent
  //div.dummy-scroll-catalog(ref='topOfContent', :style="{ '--top-position': topOfContent }")
  .catalog__content-parent
    slot
    //- @TODO required debug collector
    //infinite-template-results-collector(
    //  :pagination="pagination",
    //  :items="items",
    //  :is-new-page="!isInfinityTemplate"
    //)
    //  template(#ssr="{ isSSR }")
    //    template(v-if="isSSR")
    //      slot
    //  template(#default="{ items }")
    //    template(v-if="items")
    //      renderer(v-for="item in items", :key="item.key", :result="item.value")
  ui-limit-pagination(
    :pagination="pagination",
    @input="handlePaginate",
  )
    //ui-loader(v-if='loading')
</template>

<script>
import InfiniteTemplateResultsCollector from '@/js/resource/components/InfiniteTemplateResultsCollector.js';

const UiLimitPagination = () => import('@app/UiElements/Pagination/UiLimitPagination.vue');
const Renderer = () => import('./Renderer.vue');

export default {
  components: {
    Renderer,
    InfiniteTemplateResultsCollector,
    UiLimitPagination,
  },
  props: ['loading', 'items', 'pagination', 'isFiltered'],
  data() {
    return {
      topOfContent: 0,
      isInfinityTemplate: true,
      startPage: Math.floor(this.pagination.offset / this.pagination.limit) + 1,
    };
  },

  computed: {},

  watch: {
    isFiltered(val) {
      console.log('isFiltered', val);
      console.log('isInf', this.isInfinityTemplate);
      this.isInfinityTemplate = !val;
      console.log('isInf2', this.isInfinityTemplate);
    },
  },
  methods: {
    handleLoadMore() {
      const offset = this.pagination.offset + this.pagination.limit;
      this.handlePaginate({ offset }, true);
    },
    scrollToTop() {
      const [header] = document.getElementsByClassName('header');
      const top = header.offsetHeight;
      this.topOfContent = `${top}px`;
      this.$nextTick(() => {
        this.$refs.topOfContent.scrollIntoView({ behavior: 'smooth' });
      });
    },
    handlePaginate({ offset }, isLoadMore = false) {
      if (this.loading) {
        return;
      }
      const init = 0;
      const { limit = init } = this.pagination;
      this.isInfinityTemplate = isLoadMore;
      if (!isLoadMore) {
        this.$nextTick(() => {
          this.startPage = Math.floor(offset / limit) + 1;
        });
        this.scrollToTop();
      }
      this.$emit('update:pagination', {
        limit,
        offset,
        page: Math.floor(offset / limit) + 1,
        infinite: isLoadMore,
      });
    },
  },
};
</script>

<template>
  <div>
    <ui-preloader v-if="loading"></ui-preloader>
    <slot v-if="$scopedSlots.default" />
    <router-view v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PageLayout',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('user', ['getPersonalData']),
    ...mapActions('commonData', ['getCommonData']),
  },
  async mounted() {
    if (window.location.href === this.$router.options.base) {
      window.is_home = true;
    }

    if ('anonymous' === window.role) {
      this.getCommonData();
    } else {
      this.getPersonalData();
      this.getCommonData();
    }
  },
};
</script>

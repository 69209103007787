// import isShallowEqual from '@wordpress/is-shallow-equal';
import { equals, isEmpty } from 'ramda';
import FilterUi from '@app/Filters/Ui.vue';
// import SearchChips from '@app/List/Chips';
import Sorting from '@app/Filters/Sorting.vue';
import CatalogContent from '@app/List/Content.vue';
import CategoriesList from '../CategoriesList.vue';

export default {
  isEmpty,
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'receiveAvailable',
    'categories',
    'displayed',
    'pagination',
    'needFilters',
    'needSorting',
    'needSearch',
    'sortlist',
    'initLabel',
  ],
  components: {
    FilterUi,
    // SearchChips,
    CategoriesList,
    Sorting,
    CatalogContent,
  },
  data() {
    return {
      prepared: {},
      show_all: false,
      isDesktop: false,
      windWidth: 1200,
      label: this.initLabel,
    };
  },

  computed: {
    totalCount() {
      return Object.hasOwnProperty.call(this.pagination, 'total') ? this.pagination.total : this.value.total;
    },
    filters() {
      return this.all;
    },
    availableMap() {
      if (!this.available) {
        return {};
      }
      return this.available;

      // Object.keys(this.available).reduce((acc, x) => {
      //   this.available[x].forEach(val => {
      //     acc[val] = val

      //   })
      //   return acc
      // }, {})
    },

  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (equals(filters, oldFilters)) {
          return;
        }

        this.prepare(filters);
        if (this.receiveAvailable) {
          this.receiveAvailable({ filters, label: this.label })
        }
      },
    },
  },
  created() {
    this.receive({ label: this.label })
    window.addEventListener('resize', this.checkWidth)
    this.checkWidth();
  },

  methods: {
    showFilterModal() {
      this.$modal.show('filters-modal')
      this.$body.classList.add('is-active-filters-modal', 'vm--block-scroll')
    },
    checkWidth() {
      this.isDesktop = window.innerWidth > this.windWidth;
    },
    prepare(value) {
      this.prepared = value;
    },
    handleInputFilter(value) {
      this.prepare(value);
      this.filter();
    },
    getMultiValue(key) {
      return {
        min_value: this.value.filters[`${key}_min`],
        max_value: this.value.filters[`${key}_max`],
      };
    },
    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 });
    },
  },
};

import { curry } from 'ramda';
import makeTemplateGetter from '@aspectus/resource-template-url-getter';
import {
  defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons';
import qs from 'qs';

const queryGetter = ({ limit, offset, filters }) => {
  /**
   * create query string of filters that should be joined by repeating
   */
  const queryObj = { ...filters, limit };
  if (offset) {
    queryObj.offset = offset;
  }
  const dynamicQuery = qs.stringify(queryObj, {
    arrayFormat: 'repeat',
    addQueryPrefix: true,
  });

  return decodeURIComponent(dynamicQuery);
};

const filtersGetterBase = makeTemplateGetter('{;filters*}');
const filtersGetter = (parameters) =>
  filtersGetterBase(parameters).slice(1) || null;

const partGetter = (name, prefix) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`);
  return (parameters) => {
    const result = base(parameters);
    return result === prefix ? null : result;
  };
};

const pageGetter = partGetter('page', 'page');
const labelGetter = makeTemplateGetter('{label}');
const getParametersGetter = makeTemplateGetter('{?limit,offset}');

const orderMap = {
  label: labelGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  page: pageGetter,
  query: queryGetter,
};

const initOrder = {
  path: ['prefix', 'filters'],
};

const queryOrder = {
  path: ['prefix', 'page'],
  query: ['query'],
};

export const urlGetter = curry(([prefix, postfix], { path, query = [] }, p) => {
  const base = { prefix, postfix };
  const pathParts = path.reduce(
    (acc, x, i) => {
      acc[i] = orderMap[x] ? orderMap[x](p) : base[x];
      return acc;
    },
    Array.from({ length: path.length }, () => null)
  );
  const queryParams = query.reduce((acc, x) => {
    acc.push(orderMap[x] ? orderMap[x](p) : base[x]);
    return acc;
  }, []);
  const filteredPath = pathParts.filter((x) => null !== x).join('/');
  const replaced = filteredPath.replace(/\/\//gim, '/');
  const searchPath = queryParams.join('');
  const fullreplaced = replaced !== prefix ? `${replaced}/` : replaced;
  const prettyUrl = decodeURIComponent(fullreplaced);
  return `${prettyUrl}${searchPath}`;
});

export const friendlyUrlGenerator = (url, resource, order = initOrder) =>
  resource
    .url(urlGetter(url, order))
    .transform(jsonTransformer)
    .transform(defaultDataTransformer);

export const queryUrlGenerator = (url, resource, order = queryOrder) =>
  resource
    .url(urlGetter(url, order))
    .transform(jsonTransformer)
    .transform(defaultDataTransformer);

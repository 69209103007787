import VueI18n from 'vue-i18n';
import VueBem from '@aspectus/vue-bem';
import VModal from 'vue-js-modal';
import Tag from '@aspectus/vue-tag';
import VueMultiselect from 'vue-multiselect';
import VueGrid from '@aspectus/vue-grid';
import VueDropzoneUploader from '@aspectus/vue-dropzone-uploader';
import VueDropzone from '@aspectus/vue-dropzone';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueSelectionController from '@aspectus/vue-selection-controller';
import VTooltip from 'v-tooltip';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VueMask from 'vue-the-mask';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ResourceComponents from '@/js/resource';

// import filters from '@utils/filters'

// import 'vue-animate-transitions/dist/vue-animate-transitions.min.css'
const Slick = () => import('vue-slick');
export function install(Vue) {
  Vue.use(VueI18n);
  Vue.use(Vuex);
  Vue.use(VueRouter);
  Vue.use(VueSelectionController);
  Vue.use(Tag);
  Vue.use(VueDropzoneUploader);
  Vue.use(VueReCaptcha, { siteKey: window.CAPTCHA_KEY || '' });
  Vue.use(VueDropzone);
  Vue.use(VueGrid);
  Vue.use(VTooltip);
  Vue.use(VueMask);
  Vue.use(VueToast);
  Vue.use(VueBem, {
    blockNameKey: 'block',
  });
  Vue.use(ResourceComponents);
  Vue.component('vue-multiselect', VueMultiselect);
  Vue.component('Slick', Slick);
  Vue.use(VModal, {
    dynamic: true,
    dynamicDefaults: {
      height: 'auto',
      adaptive: true,
      scrollable: true,
      width: 430,
    },
  });
}

export default { install };

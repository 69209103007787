<template lang="pug">
include /mixins
tag(
  name="filters-modal",
  width="100%",
  height="auto",
  :adaptive="true",
): +b.filter-wrap
  row.catalog-sidebar__sections(space="lg" align="center" appearance="spaced")
    slot(name="prepend")
    template(v-for="filter in filters")
      template(
        v-if="$options.widgetsMap[filter.type]",
      )
        cell.catalog-sidebar__section(
          :key="filter.title"
        )
          tag(
            :tag="$options.widgetsMap[filter.type]",
            :value="value[filter.key] || getMultiValue(filter.key)",
            :disabled="disabled",
            :filter="filter",
            :filter-name="filter.name"
            @input="'datefield' === filter.type ? updateMultipleValues(filter.key, $event) : updateValue(filter.key  , $event)"
          )
    cell.order--2(cols="narrow")
      .input-label.opacity_0 Кнопка скинути
      control-button(
        appearance="full",
        size='2md'
        @click.prevent="() => { $emit('input', {}); }"
        ) {{ _('Скинути') }}
    cell.order--3.ma--left_auto-mac(cols="12 narrow-mac")
      bem-link(
        color="blue"
        hover="greyblue"
        size="2sm"
        weight="regular"
        target="_blank"
        appearance="underline"
        :href="organization.templatePath"
      ) {{ _('Шаблон імпорту показників') }}
    slot(name="append")
</template>

<script>
import UiMixin from '@app/Filters/mixins/UiMixin.js';
import { mapState } from 'vuex';

export default {
  mixins: [UiMixin],
  props: [
    'isDesktop',
    'disabled',
    'value',
    'available',
    'filters',
    'pagination',
    'needFilters',
    'needSorting',
    'needSearch',
  ],
  data() {
    return {
      search: '',
      search_filter: {
        label: 'search',
        title: this._('Поиск'),
        id: 'search_id',
      },
    };
  },
  computed: {
    ...mapState('commonData', ['organization']),
  },
};
</script>

export default {
  data() {
    return {
      form: {

      },
    }
  },
  mounted() {
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      // this.token = await this.$recaptcha('login')

      this.form.recaptcha = await this.$recaptcha('execute')
    },
  },
}

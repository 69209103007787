<template lang="pug">
include /mixins
+b.HEADER.header
  +b.g-container--variant_tiny
    row(align="center" :appearance="['spaced', 'column-till-lg']" justify="center-till-lg" :space="['2xl', 'none-xl'] ")
      cell.ma--right_auto-lg(cols="auto")
        +b.IMG.d--block(:src="organization.logo")
      cell.ma--right_10-lg(cols="narrow")
        +b.ROUTER-LINK.ds-caption--color_white.--weight_bold.--hover_label.--td_none.--no-decor(to="/plans/")
          icon-button(:styled="'gold'")
            template(#default="")
              +b.g-row--align_center.--space_none
                icon-calendar-check.ma--right_4
                +b.ds-caption--color_white.--weight_bold.--hover_label.--td_none.--no-decor {{ _('Плани споживання') }}
      cell(cols="narrow")
        icon-button(:styled="'ghost'")
          template(#default="")
            ui-logout(
              v-slot="{ logout }"
              v-if="isCabinet"
            )
              +b.ds-caption--color_blue.--weight_bold.--hover_greyblue.--td_none.pointer(
                  @click="logout"
                )
                  +b.g-row--align_center
                    +b.icon.icon-logout.hero--logout.ma--right_4
                    span {{ _('Вийти') }}
            +b.ROUTER-LINK.ds-caption--color_blue.--weight_bold.--hover_greyblue.--td_none(
                to="/cabinet"
                v-else
              )
              +b.g-row--align_center
                +b.icon.icon-contact.hero--account.ma--right_4
                span {{ _('Мій акаунт') }}
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
      isHome: window.is_home,
      role: window.role,
      buttonPlans: this._('Плани споживання'),
    };
  },
  mounted() {
    console.log();
  },
  computed: {
    ...mapState('commonData', ['organization']),
    isCabinet() {
      return this.$route.meta.cabinet;
    },
  },
};
</script>

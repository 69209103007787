<template lang="pug">
include /mixins
validation-observer(ref="validator", v-slot="{ valid, handleSubmit }", slim): form(
  @submit.prevent="handleSubmit(prepareData)"
  :disabled="loading",
)
   row(space="lg-md", appearance="spaced")
    cell(cols="12")
    cell(cols="12 4-mac")
      d-input(
        :label-text="fields.firstName.label",
        vid="firstName",
        :placeholder="fields.firstName.placeholder",
        rules="required|max:250"
        type="name",
        v-model="form.name",
        :disabled="loading",
      )
    cell(cols="12 4-mac")
      d-phone-input(
        :label-text="fields.phoneNumber.label",
        :value="form.phoneNumber",
        @input="setPhone"
        vid="phoneNumber",
        rules="phone|max:13",
        :disabled="loading",
        :initial-config="{ 'no-use-browser-locale': true, }"
      )
    cell(cols="12 4-mac")
      d-input(
        :label-text="fields.email.label",
        vid="email",
        name="email",
        :placeholder="fields.email.placeholder",
        rules="required|email",
        type="email",
        v-model="form.email",
        :disabled="loading",
      )
        template(#label="{ labelText }")
          +b.control-label {{ labelText }}
    cell(cols="12")
      d-textarea(
        :label-text="fields.content.label",
        vid="textarea",
        name="textarea",
        rules="required|max:500",
        type="textarea",
        v-model="form.content",
        :disabled="loading",
      )
        template(#label="{ labelText }")
          +b.control-label {{ labelText }}
    cell(cols="12")
      validation-provider(
        :rules="{ required: { allowFalse: false } }",
        v-slot="{ errors }"
        tag="div",
        name="agree_rules"
      )
        .radio-wrapper
          +b.control-input.rel
            row(align="center")
              cell
                +e.element.INPUT(
                  type="checkbox"
                  name="permissions"
                  rules="required"
                  :value="form.isAgree"
                  :disabled="loading",
                  @change="form.isAgree = !form.isAgree"
                )
              cell.ma--bottom_1
                +e.LABEL.label--static
                  +b.ds-caption.--size_5xs.--size_4xs-xs.--size_sm-lg.--color_text {{ fields.isAgree.label }}
            +b.SPAN.error-label(v-if="errors.length") {{ errors[0] }}
            +b.SPAN.error-label(v-if="non_field_errors.length") {{ non_field_errors[0] }}
      +b.BUTTON.procloud-btn.--blue.ds-caption--appearance_uppercase.bordered_4.ma--left_auto.w--100(
        type="submit"
        :disabled="loading",
        ) {{ _('Send') }}
</template>

<script>
import FormMixin, {
  defaultValidatorErrorsParser,
} from '@app/Forms/FormMixin.js';
import CaptchaMixin from '@app/Forms/CaptchaMixin';
import { clone } from 'ramda';

import { mapState, mapActions } from 'vuex';
import { contactUsResource } from '@api/communication.service';

export default {
  name: 'ContactUs',
  mixins: [FormMixin, CaptchaMixin],
  props: ['from'],
  data() {
    return {
      resource: 'contactUsResource',
      is_authenticated: window.IS_AUTHENT,
      non_field_errors: '',
      isValidPhone: true,
      loading: false,
      form: {
        name: '',
        email: '',
        phoneNumber: '',
        content: '',
        isAgree: false,
        recaptcha: '',
        fromPage: this.from,
      },
      fields: {
        firstName: {
          label: this._('Name'),
          placeholder: this._('Name'),
        },
        email: {
          label: this._('Еmail address'),
          placeholder: this._('mail@gmail.com'),
        },
        phoneNumber: {
          label: this._('Country code'),
          hint: this._('Example: 8 (912) 345-67-69'),
        },
        content: {
          label: this._(
            'Tell us about your problem. The more details, the better'
          ),
        },
        isAgree: {
          label: this._('I agree to the processing of personal data'),
        },
      },
    };
  },
  computed: {
    ...mapState('cabinet', ['personal']),
  },
  async mounted() {
    if (this.is_authenticated) {
      await this.getPersonalData();
      await this.setData();
    }
  },
  methods: {
    ...mapActions('cabinet', ['getPersonalData']),
    setData() {
      this.form.name = this.personal.firstName;
      this.form.email = this.personal.email;
      this.form.phoneNumber = clone(this.personal.phone);
    },
    setPhoneError() {
      this.$nextTick(() => {
        this.$refs.validator.setErrors({
          phoneNumber: [this._('Phone is not valid')],
        });
      });
    },
    setPhone({ value, isValid }) {
      this.form.phoneNumber = value;
      this.isValidPhone = isValid;
      if (!isValid) {
        this.setPhoneError();
      }
    },
    async prepareData() {
      this.$refs.validator.validate().then(async valid => {
        if (!valid) return;
        if (!this.isValidPhone) {
          this.setPhoneError();
          return;
        }
        await this.recaptcha();
        this.submit(valid, this.form);
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.non_field_errors = parsed.non_field_errors;
      this.$refs.validator.setErrors(parsed);
    },
    send(data) {
      this.loading = true;
      return contactUsResource.execute({}, data).then(async res => {
        if (201 === res.code) {
          const { AnswerMessage } = await import('@utils/submit.js');
          const meta = {
            messages: [
              {
                title: this._('Thank you, we will contact you shortly'),
              },
            ],
          };
          AnswerMessage(meta, this);
          this.$refs.validator.reset();
          this.$refs.validator.$el.reset();
          this.form = {
            name: '',
            email: '',
            phoneNumber: '',
            content: '',
            isAgree: false,
            recaptcha: '',
          };
          this.loading = false;
          this.$emit('close');
        }
      });
    },
  },
};
</script>

import { withDefaultProps } from '@aspectus/vue-utils';
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor';
import {
  CInput,
  CTextArea,
  CDatePicker,
  CSelect,
  CDatepickerSimple,
  CPhoneInput,
} from './inputs';
// import Select from './Select';

const dt = tag => withDefaultProps({
  element: () => tag,
  controllerComponent: () => NoneController,
})(Descriptor);

export const DInput = dt(CInput);
export const DPhoneInput = dt(CPhoneInput);
export const DTextArea = dt(CTextArea);
export const DDatePicker = dt(CDatePicker);
export const DSelect = dt(CSelect);
export const DDatepickerSimple = dt(CDatepickerSimple);

function install(Vue) {
  Vue.component('d-input', DInput);
  Vue.component('d-phone-input', DPhoneInput);
  Vue.component('d-textarea', DTextArea);
  Vue.component('d-date-picker', DDatePicker);
  Vue.component('d-date-picker-simple', DDatepickerSimple);
  Vue.component('d-select', DSelect);
}

export default { install };

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    :fill="background"
    @mouseover="hovered"
    @mouseleave="notHovered"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      :stroke="icon"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 16L16 12L12 8"
      :stroke="icon"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 12H16"
      :stroke="icon"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRight',
  data() {
    return {
      background: 'none',
      icon: '#407BFF',
      baseColors: {
        icon: '#407BFF',
        bg: 'none',
      },
      hoverColors: {
        icon: '#FFFFFF',
        bg: '#407BFF',
      },
    };
  },
  methods: {
    hovered() {
      this.icon = this.hoverColors.icon;
      this.background = this.hoverColors.bg;
    },
    notHovered() {
      this.icon = this.baseColors.icon;
      this.background = this.baseColors.bg;
    },
  },
};
</script>

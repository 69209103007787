<template lang="pug">
include /mixins
div
  +b.ds-panel--space_none.--space_xl-xl
    +e.element--offset_bottom
      row(:space="['lg', 'none-md']", align="center", justify="end", appearance="spaced")
        cell(cols="12 auto-md")
          slot(name="title")
        cell(
          v-if="isDesktop"
          cols="narrow 2-xl"
        )
    .catalog-sidebar.ma--bottom_5.ma--bottom_10-xl
      filter-ui(
        :disabled="loading",
        :value="prepared",
        :available="availableMap",
        :filters="filters",
        :need-search="needSearch",
        :pagination="pagination",
        :sortlist="sortlist",
        :show_all="show_all",
        @input="handleInputFilter",
        :is-desktop="isDesktop",
        :label="label"
      )
        template(v-if="!isDesktop", #prepend)
          cell(cols="12")
            //categories-list(
            //  :categories="categories",
            //  :label="label",
            //)
    .cell-content
      slot(name="content", :loading="loading")
</template>

<script>
import ControllerMixin from '@app/Filters/mixins/ControllerMixin.js';

export default {
  mixins: [ControllerMixin],
};
</script>

import {
  planResource,
  planDayResource,
  planImportResource,
} from '@api/plan.service';

const SET_PLAN_DATA = 'SET_PLAN_DATA';
const SET_DAY_PLAN_DATA = 'SET_DAY_PLAN_DATA';

export const planData = {
  namespaced: true,
  state: {
    plan: {},
    planDay: {},
  },
  actions: {
    async getPlanData({ commit }, payload) {
      return planResource
        .execute({ id: payload })
        .then(async res => {
          if (200 === res.code) {
            const { data } = res;
            commit(SET_PLAN_DATA, data);
          }
        })
        .catch(() => {
          window.location.href = '/not-found';
        });
    },
    async getPlanDay({ commit }, payload) {
      return planDayResource
        .execute({ id: payload.id, day: payload.day })
        .then(async res => {
          if (200 === res.code) {
            const { data } = res;
            console.log(data);
            commit(SET_DAY_PLAN_DATA, data);
          }
        })
        .catch(() => {
          window.location.href = '/not-found';
        });
    },
    // eslint-disable-next-line no-empty-pattern
    async importPlan({}, data) {
      return planImportResource
        .execute({}, data)
        .then(async res => {
          console.log(res);
        })
        .catch(async err => {
          console.log(err.statusText);
        });
    },
  },
  mutations: {
    [SET_PLAN_DATA](state, data) {
      state.plan = data.item;
    },
    [SET_DAY_PLAN_DATA](state, data) {
      state.planDay = data.item;
    },
  },
};

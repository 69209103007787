<template lang='pug'>
filter-controller(
  :receive="allProps.receive",
  :receive-available="(availableProps && availableProps.receive) || null",
  :all="allProps.result && allProps.result.items",
  :available="getTransformedAvailable",
  :displayed="displayedFilters",
  :pagination="pagination",
  :loading="allProps.loading",
  :need-filters="needFilters",
  :need-sorting="needSorting",
  :need-search="needSearch",
  :value="value",
  :init-label="label",
  @input="handleFilter",
  :sortlist="sortlist",
  v-bind="$attrs"
)
  template(v-slot:[name]="scope", v-for="(name, key) in eSlots")
    slot(v-bind="scope", :name="key")
  template(#content="props")
    slot(name="content", v-bind="props")
</template>

<script>
import ViewMixin from '@app/Filters/mixins/ViewMixin';

const eSlotsResolver = (acc, key) => {
  acc[key] = key;
  return acc;
};

const getESlots = keys => keys.reduce(eSlotsResolver, {});

export default {
  mixins: [ViewMixin],
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots));
    },
  },
};
</script>

<template lang="pug">
//- :class="{ error: errors.length }",
vue-phone-number-input(
  v-bind="config"
  v-model="phone",
  @update="changePhone"
  :disabled="disabled"
)
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: { VuePhoneNumberInput },
  props: {
    value: {
      default: '',
    },
    initialConfig: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
  },
  data() {
    return {
      config: {
        'border-radius': 5,
        required: false,
        translations: {
          countrySelectorLabel: '',
          countrySelectorError: '',
          phoneNumberLabel: '',
          example: this._('Example:'),
        },
        'no-country': true,
        'no-example': false,
        'show-code-on-list': true,
        'no-flags': true,
        color: '#133E63',
        errorColor: '#FF8D86',
        validColor: '#DDDFE1',
        'no-use-browser-locale': false,
        ...this.initialConfig,
      },
      formattedNumberPhone: '',
      phone: this.value,
    };
  },
  watch: {
    value(val) {
      this.phone = val;
    },
  },
  methods: {
    changePhone(object) {
      const { formattedNumber = null, isValid } = object;
      if (formattedNumber) {
        this.formattedNumberPhone = formattedNumber;
        this.$emit('input', { value: this.formattedNumberPhone, isValid });
      }
    },
  },
};
</script>

<style lang="sass">
.vue-phone-number-input *
  font-family: 'Montserrat'
.country-selector[data-v-46e105de],
.input-tel[data-v-e59be3b4]
  height: auto
//   width: 80px
//   min-width: 80px
  // max-width: 80px

.input-tel__label[data-v-e59be3b4]
  color: #4A7397 !important
  top: calc(-15px * 1.5)
  left: unset
  right: 0
  font-size: 14px
  font-weight: 500
.input-tel.has-hint .input-tel__label[data-v-e59be3b4], .input-tel.has-value .input-tel__label[data-v-e59be3b4]
  font-size: 12px

.input-tel.has-hint .input-tel__input[data-v-e59be3b4], .input-tel.has-value .input-tel__input[data-v-e59be3b4],
.country-selector.has-hint .country-selector__input[data-v-46e105de], .country-selector.has-value .country-selector__input[data-v-46e105de]
  padding-top: 0
  background: #F8F7F8
.input-tel__input[data-v-e59be3b4]
  cursor: text !important
  background: #F8F7F8
  &:focus
    background: #ffffff
  &:hover
    border: 1px solid var(--theme-relief-3)
.country-selector__input[data-v-46e105de].country-selector__input[data-v-46e105de],
.input-tel__input[data-v-e59be3b4].input-tel__input[data-v-e59be3b4]
  height: 48px
  box-shadow: none !important
  // border-color: #DDDFE1 !important
</style>

<template funtional lang="pug">
  include /mixins
  transition(name="fadeIn")
    +b.loader-wrapper(v-if="loading")
      +e.bg(:class="loaderColor")
</template>

<script>
export default {
  name: 'ui-preloader',
  props: {
    loaderColor: String,
    loading: Boolean,
  },
}
</script>

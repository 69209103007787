<template lang="pug">
  include /mixins
  +b.seo-wrap(
    :class="{'is-active': !showBtn}"
  )
    +b.seo-text.t-content(
      :style="{ maxHeight: `${contentHeight}px` }",
      ref="text"
    )
      slot
    +b.seo-bottom
      +e.line
      .pa--top_3
        +e.SPAN.btn.ds-caption--size_2xs.--color_blue.--hover_boldblue.pointer(v-if="showBtn && !showSeo" @click="showSeo = !showSeo; setHeight();") {{ _('read more') }}
        +e.SPAN.btn.ds-caption--size_2xs.--color_blue.--hover_boldblue.pointer(v-else-if="showBtn && showSeo" @click="showSeo = !showSeo; setHeight();") {{ _('read less') }}
</template>

<script>
export default {
  name: 'SeoText',
  data() {
    return {
      showBtn: false,
      showSeo: false,
      contentHeight: this.visibleHeight,
      scrollHeight: 0,
    };
  },
  props: {
    visibleHeight: {
      default: 0,
    },
  },
  mounted() {
    this.innerHeight();
  },
  methods: {
    innerHeight() {
      const delay = 500;
      setTimeout(() => {
        if (this.$refs.text.scrollHeight > this.visibleHeight) {
          this.scrollHeight = this.$refs.text.scrollHeight;
          this.$refs.text.style.maxHeight = `${this.visibleHeight}px`;
          this.showBtn = true;
        }
      }, delay);
    },
    setHeight() {
      if (this.showSeo) {
        this.contentHeight = this.scrollHeight;
        return;
      }
      this.contentHeight = this.visibleHeight;
    },
  },
};
</script>

<template lang="pug">
include /mixins
div
  validation-observer(ref="validator", v-slot="{ valid, handleSubmit }", slim): form(
    :disabled="loading",
    @submit.prevent="handleSubmit(prepareData)"
  )
    +b.H3.vm-modal-title {{ title }}
    +b.P.vm-modal-subtitle(v-if="subtitle") {{ subtitle }}

    row(space="lg-md", appearance="spaced")
      validation-provider.relative(
        v-slot="{ errors }"
        name="forbidden",
        slim
      )
        cell.relative(
          cols="12"
        )
          +b.ds-caption--size_2xs.--variant_error.--appearance_right(v-if="errors && errors[0]") {{ errors[0] }}
      cell(cols="12")
        d-input(
          :label-text="fields.email.label",
          vid="email",
          :placeholder="fields.email.placeholder",
          rules="required|email",
          type="email",
          v-model="form.email",
          :disabled="isSendedCode || loading"
        )
      cell(cols="12", v-if="!isSendedCode")
        control-button(:disabled="loading", appearance="full", type="submit") {{ _('Відновити пароль') }}
</template>

<script>
import FormMixin from '@app/Forms/FormMixin.js';
import {
  passwordResetResource,
} from '@api/auth.service';
import TimerMixin from './TimerMixin.js';

export default {
  name: 'specify-email',
  mixins: [FormMixin, TimerMixin],
  props: {
    partialToken: {
      type: String,
      required: false,
    },
    action: {
      type: String,
      required: false,
    },
  },

  data() {
    this.title = this._('Відновлення паролю');
    this.subtitle = this._(
      'Повідомлення з порядком зміни email буде відправлено на новий email'
    );
    return {
      requiredCode: true,
      form: {
        email: '',
      },
      fields: {
        email: {
          label: this._('E-mail'),
        },
      },
    };
  },
  methods: {
    async prepareData() {
      this.$refs.validator.validate().then(async valid => {
        if (!valid) return;
        this.submit(valid, this.form);
      });
    },
    send(data) {
      return passwordResetResource.execute({}, data).then(async res => {
        const { AnswerMessage } = await import('@utils/submit.js');
        AnswerMessage(res.data, this);
        this.$emit('finish');
      });
    },
  },
};
</script>

import './public-path';

import Vue from 'vue';
import I18n from 'vue-i18n';
import { extend } from 'vee-validate';
import smoothscroll from 'smoothscroll-polyfill';
import '@/js/validation';
import {
  required,
  confirmed,
  email,
  min,
  min_value as minValue,
  max,
  max_value as maxValue,
  ext,
  size,
} from 'vee-validate/dist/rules';
import { install } from '@/install';
import { createApp } from '@/app';

import '@utils';

import './styles/index.sass';

import appComponents from './js/components';

const Shareon = require('shareon');

Shareon.init();
smoothscroll.polyfill();
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.directive('table-wrapper', {
  bind: node => {
    const tables = Array.from(node.querySelectorAll('table'));
    const zero = 0;
    if (zero < tables.length) {
      tables.forEach(el => {
        const wrapper = document.createElement('div');
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
        wrapper.classList.add('table-wrapper');
      });
    }
  },
});

Vue.config.productionTip = false;

Vue.use(install);

Vue.use(appComponents);

const { app } = createApp({
  Vue,
  I18n,
});

app.$mount('#app');

extend('numeric', { ...required, message: app._('Поле должно быть числом.') });
extend('required', { ...required, message: app._('This field is required!') });
extend('confirmed', { ...confirmed, message: app._('Passwords must match.') });
extend('min', {
  ...min,
  message: (e, a) => `${app._('Количество символов должно быть не меньше')} ${a.length}`,
});
extend('min_value', { ...minValue, message: app._('Minimum value is {min}.') });
extend('max', {
  ...max,
  message: (e, a) => `${app._('Количество символов не должно превышать')} ${a.length}`,
});
extend('max_value', { ...maxValue, message: app._('Maximum value is {max}.') });
extend('ext', { ...ext, message: app._('Invalid file format') });
extend('email', { ...email, message: app._('Email is invalid.') });
extend('size', {
  ...size,
  message: (e, a) => app._('The file size must be less than ') + a.size / 1024 + app._('Mb'),
});

extend('phone', {
  validate(value) {
    const MOBILEREG = /^[+() -\d]+$/;

    // Regex for a international phone number
    return MOBILEREG.test(value);
  },
  computesRequired: true,
  message: app._('Phone is not valid'),
});

extend('password', {
  validate(value) {
    if (7 > value.length) return false;
    const PASSWORD_REG = /^(?=.*\d)?(?=.*[a-zA-ZА-Яа-я]).{8,}$/;
    return PASSWORD_REG.test(value);
  },
  computesRequired: true,
  message: app._('The password must contain at least: 1 letter. Min length 8'),
});

extend('emailMethod', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ\+0-9_.-]{2,64}@[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ0-9_.-]+[.a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{0,6}$/.test(
      value
    );
  },
  message() {
    return app._('Поле должно быть действительным электронным адресом.');
  },
});

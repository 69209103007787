<template functional lang="pug">

include /mixins.pug

+b.TAG(
  v-bem:[props.blockName]="{ styling: props.styling, size: props.size, kind: props.kind, variant: props.variant, appearance: props.appearance }"
  v-state="{ disabled: props.disabled, readonly: props.readonly, invalid: props.invalid }"
  :class="[data.staticClass, data.class]"
  v-bind="data.attrs",
  v-on="data.on",
  :tag="props.tag",
  :readonly="props.readonly",
  :disabled="props.disabled",
)
  span(v-bem:[props.blockName].body="")

    slot(name="prepend")
    +e.SPAN(
      v-bem:[props.blockName].element="{ kind: 'content', primary: true }"
    )
      slot
    span(
      v-bem:[props.blockName].element="{ kind: 'icon', append: true }",
      v-if="props.icon || scopedSlots.icon"
    )
      slot(name="icon")
        icon(:name="props.icon", :size="props.iconSize")
    slot(name="append")

</template>

<script>

export default {
  name: 'control-root-element',
  props: {
    blockName: String,
    innerElement: {},

    kind: {},
    variant: {},
    size: { default: 'lg' },
    styling: { default: 'default' },
    appearance: {},
    disabled: Boolean,
    readonly: Boolean,
    invalid: Boolean,
    tag: { default: 'div' },
    iconSize: { default: '2xl' },
    icon: {},
  },
};

</script>

export default {
  props: {
    time: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      defaultTime: this.time,
      currentTime: '00:00',
      timer: null,
      timeIsOut: false,
    }
  },
  watch: {
    currentTime(time) {
      const zero = '00:00'
      if (time === zero) {
        this.stopTimer()
      }
    },
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    startTimer() {
      this.timeIsOut = false
      this.timer = setInterval(() => {
        this.defaultTime--
        this.currentTime = this.convertTime((this.defaultTime).toFixed(0))
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
      this.defaultTime = this.time
      this.currentTime = '00:00'
      this.timeIsOut = true
    },
    convertTime(seconds) {
      let minutes = Math.floor(seconds / 60)
      let hours = ''
      if (59 < minutes) {
        hours = Math.floor(minutes / 60)
        hours = (10 <= hours) ? hours : `0${ hours}`
        minutes -= (hours * 60)
        minutes = (10 <= minutes) ? minutes : `0${ minutes}`
      }
      minutes = (10 <= minutes) ? minutes : `0${ minutes}`
      seconds = Math.floor(seconds % 60)
      seconds = (10 <= seconds) ? seconds : `0${ seconds}`
      if ('' !== hours) {
        return `${hours }:${ minutes }:${ seconds}`
      }
      return `${minutes }:${ seconds}`
    },
  },
}

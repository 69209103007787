import {
  mergeContext,
  withDefaultProps,
  internalPropsMagicTransform,
} from '@aspectus/vue-utils';
import InputElement from './InputElement';
import Checker from './Checker';
import RootElement from './RootElement';
import Select from './Select';
import Datepicker from './Datepicker';
import PhoneInput from './PhoneNumber.vue';

const DATE_CONFIG = 'datetime';

function formatFabric(d, config = DATE_CONFIG) {
  return {
    stringify: date => (date ? d(date, config) : ''),
    parse: date => {
      const newDate = d(new Date(), config);
      if (newDate.includes('/')) {
        return new Date(date);
      }
      if (newDate.includes('.')) {
        return new Date(date.split('.').reverse().join('/'));
      }
      return new Date(date);
    },
  };
}
const wrp = (tag, blockName, root = InputElement) => withDefaultProps({ blockName, innerElement: () => tag })(root);
const eventHandlerFunctionFactory = data => e => data.on && data.on.input && data.on.input(e.target.value);
function eventHandler(control) {
  return {
    functional: true,
    render: (h, context) => h(
      control,
      // eslint-disable-next-line prefer-object-spread
      internalPropsMagicTransform(
        control,
        {
          ...context.data, // eslint-disable-next-line prefer-object-spread
          on: Object.assign({}, context.data.on, {
            input: eventHandlerFunctionFactory(context.data),
          }),
        }
      ),
      context.children
    ),
  };
}
const DatePickerBase = {
  functional: true,
  props: ['formatFabric', 'dateFormat', 'format'],
  render(h, context) {
    // eslint-disable-next-line no-shadow
    const { format, dateFormat, formatFabric } = context.props;

    return h(
      InputElement,
      mergeContext(context.data, {
        attrs: {
          format,
          formatter: formatFabric(
            context.parent.$d.bind(context.parent),
            dateFormat
          ),
        },
      }),
      context.children
    );
  },
};

export const CInput = wrp(eventHandler('input'), 'control-input');
export const CTextArea = wrp(eventHandler('textarea'), 'control-textarea');
export const CSelect = wrp(eventHandler('input'), 'control-input', Select);
export const CDatepickerSimple = wrp(
  eventHandler('input'),
  'control-input',
  Datepicker
);
export const CPhoneInput = wrp(
  eventHandler('input'),
  'control-input',
  PhoneInput
);
export const CDatePicker = withDefaultProps({
  blockName: 'control-date-picker',
  innerElement: 'vue-date-picker',
  rangeSeparator: ' - ',
  lang: window.language,
  formatFabric: () => formatFabric,
  dateFormat: DATE_CONFIG,
})(DatePickerBase);
export const CCheckbox = withDefaultProps({ b: 'control-checkbox' })(Checker);
export const CRadio = withDefaultProps({ b: 'control-radio' })(Checker);
export const CButton = withDefaultProps({
  blockName: 'control-button',
  tag: 'button',
})(RootElement);

function install(Vue) {
  Vue.component('control-input', CInput);
  Vue.component('control-textarea', CTextArea);
  Vue.component('control-date-picker', CDatePicker);
  Vue.component('control-checkbox', CCheckbox);
  Vue.component('control-radio', CRadio);
  Vue.component('control-select', CSelect);
  Vue.component('control-datepicker-simple', CDatepickerSimple);
  Vue.component('control-phone-input', CPhoneInput);
  Vue.component('control-button', CButton);
}

export default { install };

import { baseResource, createReceiver } from '@resource/resource';

import { prefixAPI } from '@resource/api';

import { queryUrlGenerator } from '@aspectus/vue-friendly';

// const order = {
//   path: ['prefix', 'label', 'filters', 'page'],
// };

const PLANS_LIST_URL = prefixAPI('agreements/');
const PLANS_LIST_URL_ADDITIONAL = prefixAPI('plans/');
const FILTERS_LIST_URL = prefixAPI('all-filters/');

export const attributesResource = createReceiver(FILTERS_LIST_URL);

export const plansListUrl = queryUrlGenerator(
  [PLANS_LIST_URL, ''],
  baseResource
);

export const plansListAdditionalUrl = queryUrlGenerator(
  [PLANS_LIST_URL_ADDITIONAL, ''],
  baseResource
);

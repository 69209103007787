import { createReceiver, createSender } from '@resource/resource';
import { prefixAPI } from '@resource/api';

const PLAN_URL = prefixAPI('plan/{id}/');
const PLAN_DAY_URL = prefixAPI('plan/{id}/?day={day}');
const PLAN_IMPORT_URL = prefixAPI('plan/import/');
const PLAN_UPDATE_URL = prefixAPI('plan/{id}/update/');

export const planResource = createReceiver(PLAN_URL);
export const planDayResource = createReceiver(PLAN_DAY_URL);
export const planImportResource = createSender(PLAN_IMPORT_URL);
export const planUpdateResource = createSender(PLAN_UPDATE_URL);

<template lang="pug">
  include /mixins.pug
  +b.ui-uploader
    +e.list.order--2
      div(v-if='files.length > 0')
        +e.element.--offset_bottom
          div(
            v-for='(file, index) in files'
            v-if="file && !file.Delete"
          )
            //+b.relative.ma--left_5.ma--bottom_1
            //  +b.ui-uploader__actions
            //    +b.close.--variant_3.--variant_relative(@click='removeFile(index)')
            //      +b.I.icon-close.pointer &times;
            validation-provider(
              tag="div"
              v-slot="{errors}"
              :name="'images_' + index"
            )
              +b.ds-panel.--space_xs(v-if="errors.length")
                +e.element.--offset_top
                  +b.P.ds-caption.--size_xs.--color_red.--lh_default {{ errors[0] }}
    +e.content
      +e.wrapper
        +b.g-row.--space_md.--appearance_spaced.--appearance_column
          +b.g-cell.g-cols
            label
              slot(name='button')
              input(
                ref="inputFile"
                style="display:none"
                type='file'
                :id='inputId'
                :name='inputId'
                :accept='acceptedFilesLocal'
                @change='onFileChange'
              )
          //+b.g-cell.g-cols.--auto-sm
          //  +b.P.ds-caption.--size_xxs.--lh_default {{ _("Вимоги до завантажуваних файлів") }}
          //  +b.P.ds-caption.--size_xxs.--lh_default {{ `(${_("Максимальна кількість файлів -")} ${maxFiles}, ${_("максимальний розмір файлу -")} ${maxSize} ${_("Мб.")}, ${_("дозволені формати -")} ${acceptedFiles})` }}
          //    span(v-if="minResolution") , {{ _("минимально рекомендуемое разрешение") }} {{ minResolution }}
    //+e.errors
    //  slot(name='errors' :fileErorrs='fileErorrs')
    //    +b.P.ds-caption.--size_xs.--color_red.--lh_default(v-if='fileErorrs.limit') {{ _("Перевищено максимальну кількість файлів для завантаження") }}
    //    +b.P.ds-caption.--size_xs.--color_red.--lh_default(v-if='fileErorrs.size') {{ _("Перевищена максимальна вага файла для завантаження") }}
    //    +b.P.ds-caption.--size_xs.--color_red.--lh_default(v-if='fileErorrs.type') {{ _("Не підтримуваний формат файлу") }}
    //    +b.P.ds-caption.--size_xs.--color_red.--lh_default(v-if='fileErorrs.height') {{ _("Максимальна висота зображення - ") }} {{ maxHeight }}px
    //    +b.P.ds-caption.--size_xs.--color_red.--lh_default(v-if='fileErorrs.width') {{ _("Максимальна ширина зображення - ") }} {{ maxWidth }}px
      slot(name='required')
</template>
<script>
import { getMimeType } from '@utils/images';
/* eslint-disable */

const imageTypes = [
  'data:image/jpeg;base64',
  'data:image/jpg;base64',
  'data:image/png;base64',
  'data:image/gif;base64',
  '.jpeg',
  '.jpe',
  '.jpg',
  '.png',
  '.gif',
];

export default {
  name: 'UiUploader',

  props: {
    inputId: {
      type: String,
      default: 'upload',
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    acceptedFiles: {
      type: String,
      default: '.jpg, .jpeg, .png',
    },
    buttonText: {
      type: String,
      default: 'Upload file',
    },
    value: {
      type: Array,
      default: () => [],
    },
    minResolution: {},
    maxWidth: {
      default: 4000,
    },
    maxHeight: {
      default: 4000,
    },
  },

  data() {
    return {
      acceptedFilesLocal: '',
      bytes: 1000,
      megabyte: 1048576,
      files: [],
      zero: 0,
      one: 1,
      fileErorrs: {
        limit: false,
        size: false,
        type: false,
        width: false,
        height: false,
      },
      defaultFilesIsSetted: false,
      image: {},
    };
  },

  watch: {
    value: {
      handler() {
        if (!this.defaultFilesIsSetted) {
          this.setDefaultFiles();
        }
      },
    },
  },

  computed: {
    isUploaderDisabled() {
      return this.files.length === this.maxFiles;
    },
  },

  mounted() {
    this.acceptedFilesLocal = this.acceptedFiles.replace(/ /g, '');
    this.setDefaultFiles();
  },

  methods: {
    setDefaultFiles() {
      if (this.value && this.zero < this.value.length) {
        this.files.push(...this.value);
        this.defaultFilesIsSetted = true;
      }
    },

    isImageFile(src) {
      const lowercaseUrl = src.toLowerCase();
      const imageTypeIsIncluded = imageTypes.some((el) =>
        lowercaseUrl.includes(el)
      );
      return imageTypeIsIncluded;
    },

    onFileChange(e) {
      this.defaultFilesIsSetted = true;
      this.fileErorrs.size = false;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createFile(files);
      this.$emit('uploadFile');
    },

    emitFiles() {
      const timeout = 100;
      setTimeout(() => {
        this.$emit('input', this.files);
        this.$refs.inputFile.value = '';
      }, timeout);
    },

    async createFile(files) {
      await this.validateFile(files).then(() => {
        const timeout = 300;
        setTimeout(() => {
          const errorsIsClean = this.checkErrors();
          if (!errorsIsClean) return;

          const [file] = files;
          const blob = URL.createObjectURL(file);
          const reader = new FileReader();

          reader.onload = (e) => {
            const { result } = e.target;

            this.image = {
              src: result,
              type: getMimeType(result, file.type),
            };
            this.files.push(this.image);
            this.emitFiles();
          };

          reader.readAsDataURL(file);
        }, timeout);
      });
    },

    checkErrors() {
      return Object.values(this.fileErorrs).every((el) => !el);
    },

    async validateFile(files) {
      this.validateFileFormat(files);
      this.validateFilesLength(files);
      this.validateFileSize(files);
      await this.validateResolution(files);
    },

    async validateResolution(files) {
      return new Promise((resolve, reject) => {
        // Clear resolution errors
        this.fileErorrs.width = false;
        this.fileErorrs.height = false;
        Object.keys(files).forEach((el, index) => {
          const reader = new FileReader();
          reader.readAsDataURL(files[el]);
          reader.onload = (e) => {
            // Resolve promise if it isn't image file
            if (!this.isImageFile(e.target.result)) {
              resolve();
            }
            // Create new image for getting it height and width
            let img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              // Check height of image
              if (this.maxWidth < img.width) {
                this.fileErorrs.width = true;
                reject();
              }
              // Check height of image
              if (this.maxHeight < img.height) {
                this.fileErorrs.height = true;
                reject();
              }
              const one = 1;
              if (index === files.length - one) {
                resolve();
              }
            };
          };
        });
      });
    },

    validateFileFormat(files) {
      const acceptedFormats = this.acceptedFilesLocal.split(',');
      // Get file format
      let fileFormat = files[0].name.split('.');
      this.fileErorrs.type = false;
      fileFormat = `.${fileFormat[fileFormat.length - this.one]}`;
      // Check if files includes accepted format
      if (this.zero > acceptedFormats.indexOf(fileFormat.toLowerCase())) {
        this.fileErorrs.type = true;
      }
    },

    validateFilesLength(files) {
      this.fileErorrs.limit = false;
      // Get not deleted files for limit validation
      const isNotDeletedItems = this.files.reduce((acc, el) => {
        if (el && !el.Delete) acc++;
        return acc;
      }, 0);
      // Check if files limit is valid
      if (isNotDeletedItems + files.length > parseInt(this.maxFiles)) {
        this.fileErorrs.limit = true;
      }
    },

    validateFileSize(files) {
      // Check if files size is valid
      this.fileErorrs.size = Object.keys(files).some((el) => {
        return files[el].size > parseInt(this.maxSize) * this.megabyte;
      });
    },

    removeFile(index) {
      // Get file for delete
      const file = this.files[index];
      if (file && file.id) {
        // Add delete key for images from server
        file.Delete = true;
      } else {
        // Remove deleted file if it's not server file
        this.files.splice(index, this.one);
      }
      this.emitFiles();
    },

    resetFiles() {
      this.files = [];
    },
  },
};
</script>

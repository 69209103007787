import { renderSlim } from '@aspectus/vue-utils';

export default {
  name: 'infinite-template-results-collector',

  props: {
    items: {},
    pagination: {},
    isNewPage: Boolean,
  },

  data() {
    return {
      cache: [],
      isSSR: true,
    };
  },

  watch: {
    items(nval) {
      this.itemsReceived(nval);
    },
  },

  computed: {
    collected() {
      return this.cache;
    },
  },

  methods: {
    itemsReceived(items) {
      if (this.isNewPage || 0 === this.pagination.offset) {
        this.isSSR = false;
      }

      if (items && items.length) {
        if (this.isNewPage) {
          this.cache = [];
        }
        this.cache.push({
          key: Date.now(),
          value: this.items,
        });
      }
    },
  },

  render(h) {
    return renderSlim([this.$scopedSlots.ssr({
      isSSR: this.isSSR,
    }), this.$scopedSlots.default({
      items: this.collected,
    })], h, 'tag', { class: 'catalog__contents' });
  },
};

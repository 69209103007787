<script>
import { logoutResource } from '@api/auth.service';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';

import { confirmWithPromise } from '@utils/helpers';

export default {
  name: 'ui-logout',
  mixins: [LoadingStateMixin],

  methods: {
    async logout() {
      const question = this._('Are you sure you want to log out?');
      await confirmWithPromise(question, this);
      await this.$load(
        logoutResource.execute().then(res => {
          if (res.location) {
            window.location.href = res.location || res.data.item.link;
          } else {
            window.location.href = '/auth/login';
          }
        })
      );
    },
  },
  render() {
    return this.$scopedSlots.default({
      logout: this.logout,
    });
  },
};
</script>

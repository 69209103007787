/* eslint-disable prefer-destructuring, prefer-const */
import { evolve } from 'ramda';
import qs from 'qs';

const dateFrom = date => new Date(date).toISOString();
export const dateRangeFrom = evolve({ starts: dateFrom, ends: dateFrom });

export function parsePaginationUrl() {
  const { search } = window.location;
  if (search.includes('limit') || search.includes('offset')) {
    const limit = search.match(/limit=+(\d+)/);
    const offset = search.match(/offset=+(\d+)/);
    return {
      limit: limit ? Number(limit[1]) : null,
      offset: offset ? Number(offset[1]) : null,
    };
  }
  return {};
}

export function friendlyUrlParser() {
  let path = decodeURIComponent(window.location.pathname);
  const zero = 0;
  const parsedUrl = {};
  if (path.indexOf('filters') > zero) {
    path = path.split('/page')[0];
    const hashes = path.split('filters/')[1].split(';');
    hashes.map(hash => {
      let [key, val] = hash.split('=');

      parsedUrl[key] = [];
      if (!val) return false;
      if (val.indexOf(',') > zero) {
        val = val.split(',');
        val.forEach(v => {
          if (v.indexOf('/') > zero) {
            parsedUrl[key].push(v.split('/')[zero]);
          } else {
            parsedUrl[key].push(v);
          }
        });
      } else if (val.indexOf('/') > zero) {
        parsedUrl[key].push(val.split('/')[zero]);
      } else {
        parsedUrl[key].push(val);
      }
      return false;
    });
  }
  return parsedUrl;
}

/**
 * @param {string} url
 */
export const queryStringParser = url => {
  /**
   * Parse url for creating an object of selected attributes
   * `ignoreQueryPrefix` - this option removes the `?` character from url
   */
  const parsed = qs.parse(url, { ignoreQueryPrefix: true, comma: true });

  const prettified = Object.keys(parsed).reduce((acc, key) => {
    if ('limit' !== key && 'offset' !== key) {
      acc[key] = parsed[key];
    }
    return acc;
  }, {});
  return prettified;
};

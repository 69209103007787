import { omit } from 'ramda';

export const NBSP = '\xa0';
export const FILTERS_SHORTEN = [
  'gender',
  'age',
  'media_type',
  'country',
];
export const FILTERS_EXTENDED = [
  'language',
  'website_type',
  'technologies',
  'daily_likes',
  'total_likes',
  'daily_shares',
  'total_shares',
  'created_between',
  'seen_between',
  'button',
  'author',
  'text',
];
export const FILTERS_ALL = FILTERS_SHORTEN.concat(FILTERS_EXTENDED);

export const omitExtended = omit(FILTERS_EXTENDED);

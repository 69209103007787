import Base from './Base';
import Ui from './UiElements';
import Icons from './UiElements/i';
import ControlsComponents from './Controls';
import bem from './bem';
import Forms from './Forms';
import ListElements from './List';
// import Icons from './UiElements/i';

export function install(Vue) {
  Vue.use(Base);
  Vue.use(Ui);
  Vue.use(Icons);
  Vue.use(ControlsComponents);
  Vue.use(bem);
  Vue.use(Forms);
  Vue.use(ListElements);
  // Vue.use(Icons);
}

export default { install };

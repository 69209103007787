<template lang="pug">
include /mixins.pug
+b.categories-filter
  +e.UI-ACCORDION.category(
    v-for="category in categories",
    :key="category.slug",
    :init="getActiveParent(category)"
  )
    template(
      #default="{ activeState, toggleActive, collapse, style: { icon, myHeight }, isDesktop, ...other }"
    ): div
      +e.item
        //- @click.prevent="() => { label = category.label; handleInputFilter({}); }"
        +e.TAG.title(
          :class="{ 'ds-link': category.slug !== label, 'is-active': category.slug === label }"
          :tag="category.slug === label ? 'p' : 'a'"
          v-bind="category.slug === label ? {} : { href: category.link } "
        ) {{ category.title }}
        +e.icon.ds-inliner--size_md(
          @click.prevent="toggleActive()"
          :class="{ 'is-rotate': activeState}"
        )
          +b.ds-inliner__body
            arrow-down(
              color="#333E63"
            )
      transition(name='fadeIn')
        +e.children(v-show="activeState")
          +e.P.child(
            v-for="child in category.children"
            :key="child.slug"
          )
            +e.TAG.link(
              :class="{ 'ds-link': child.slug !== label, 'is-active': child.slug === label }"
              v-bind="child.slug === label ? {} : { href: child.link } "
              :tag="child.slug === label ? 'span' : 'a'"
            ) {{ child.title }}

</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
    },
  },

  methods: {

    getActiveParent(category) {
      return category.slug === this.label || category.children.map(({ slug }) => slug).includes(this.label)
    },
  },
}
</script>

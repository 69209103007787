import { progressBar } from '@utils/progress';
import { mapDots } from '@utils/map';
import { userDeviceOSDetector } from '@utils/userAgent';
import { setFaviconUrl } from '@utils/urls';

document.addEventListener('DOMContentLoaded', () => {
  userDeviceOSDetector();
  progressBar();
  mapDots();
  setFaviconUrl();
});

<template lang='pug'>
resource-loader-controller(:resource="attributesResource", v-slot="allProps")
  search-filter(
    v-bind="$attrs",
    :value="filters",
    @input="changeFilters",
    :all-props="allProps",
    :available-props="{}",
    :pagination="pagination",
    :need-filters="needFilters",
    :need-sorting="needSorting",
    :needSearch="needSearch",
    :label="label",
    :sortlist="sortlist"
  )
    template(v-slot:[name]="scope", v-for="(name, key) in eSlots")
      slot(v-bind="scope", :name="key")

    template(#content="props")
      slot(name="content", v-bind="props")
</template>
<script>
import SearchFilter from '@app/Filters';

const eSlotsResolver = (acc, key) => {
  acc[key] = key;
  return acc;
};

const getESlots = keys => keys.reduce(eSlotsResolver, {});

export default {
  components: { SearchFilter },
  props: [
    'space',
    'filters',
    'pagination',
    'attributesResource',
    'needFilters',
    'needSorting',
    'needSearch',
    'label',
    'sortlist',
  ],
  data() {
    return {};
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots));
    },
  },
  methods: {
    selectTags() {},
    changeFilters(value) {
      const params = Object.assign(value, { label: this.label })
      this.$emit('update:filters', params);
    },
  },
};
</script>

import Eye from './Eye.vue';
import EyeOff from './EyeOff';
import CalendarCheck from './CalendarCheck';
import Phone from './Phone';
import Pin from './Pin';
import Mail from './Mail';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';

export default function install(Vue) {
  Vue.component('IconEye', Eye);
  Vue.component('IconEyeOff', EyeOff);
  Vue.component('IconCalendarCheck', CalendarCheck);
  Vue.component('IconPhone', Phone);
  Vue.component('IconPin', Pin);
  Vue.component('IconMail', Mail);
  Vue.component('IconArrowLeft', ArrowLeft);
  Vue.component('IconArrowRight', ArrowRight);
}

<template lang="pug">
  include /mixins
  div.rel
    .input-label {{ _('Період дії додаткової угоди') }}
    +b.g-row.--appearance_spaced.--align_center.--space_none
      +b.g-cell.g-cols.--auto-xs
        +b.date-picker-wrapper
          d-date-picker-simple(
            v-model='checked[0]',
            :config="{ maxDate: checked[1] }"
            :placeholder='filter.title'
            autocomplete="off"
            name='startDate'
            @on-change="debounceFn"
          )
          +e.icon.icon-date
      +b.g-cell.g-cols.--auto-xs
        +b.date-picker-wrapper
          d-date-picker-simple(
            v-model='checked[1]',
            :config="{ minDate: checked[0] }"
            :placeholder='filter.title'
            autocomplete="off"
            name='endDate'
            @on-change="debounceFn"
          )
          +e.icon.icon-date
</template>

<script>
import { debounce, isEqual } from 'lodash';

export default {
  name: 'DateRangeWidget',

  props: {
    value: {},
    filter: {},
  },

  data() {
    return {
      checked: [null, null],
      debounceFn: () => {},
      labels: {
        startDate: this._('Період дії додаткової угоди'),
      },
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nval, oval) {
        this.setValue(nval, oval);
      },
    },
  },

  mounted() {
    const timeout = 200;
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.debounceFn = debounce(this.submit, timeout);
  },

  methods: {
    filterValue(val) {
      return (val || []).filter(e => e);
    },

    setValue(nval, oval) {
      const newValue = this.filterValue(nval);
      const oldValue = this.filterValue(oval);

      if (!isEqual(newValue, oldValue)) {
        let value = nval;

        if (!nval) {
          value = [];
        }
        console.log(value);
        this.$set(this.checked, 1, value[1]);

        this.$nextTick(() => {
          this.$set(this.checked, 0, value[0]);
        });
      }
    },

    submit() {
      const isEmpty = this.checked.every(el => !el);
      if (isEmpty) {
        return;
      }
      this.$nextTick(() => {
        const val = this.checked;
        this.$emit('input', val);
      });
    },
  },
};
</script>

<template lang="pug">
include /mixins
control-button(:styling="styled" :size="['md', 'lg-mac']")
  template(#append)
  slot

</template>

<script>
export default {
  name: 'ButtonWithIcon',
  props: {
    styled: {
      type: String,
    },
  },
};
</script>

import Vuex from 'vuex';
import { user } from './user';
import { commonData } from './organization';
import { planData } from './plan';

export default function createStore() {
  return new Vuex.Store({
    modules: {
      user,
      commonData,
      planData,
    },
  });
}

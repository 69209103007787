// const Sorting = () => import('@app/Filters/Sorting.vue');
// const SliderWidget = () => import('@app/Filters/Widgets/SliderWidget.vue');
// const CheckboxWidget = () => import('@app/Filters/Widgets/CheckboxWidget.vue');
import SelectWidget from '@app/Filters/Widgets/SelectWidget';
import DateRangeWidget from '@app/Filters/Widgets/DateRangeWidget';
// import CombinedInputWidget from '@app/Filters/Widgets/CombinedInputWidget'
// import AutocompleteMultiselectWidget from '@app/Filters/Widgets/AutocompleteMultiselectWidget'
// import RangeWithVariantsWidget from '@app/Filters/Widgets/RangeWithVariantsWidget'
// import SimpleMultiselectWidget from '@app/Filters/Widgets/SimpleMultiselectWidget'
// import InputWidget from '@app/Filters/Widgets/InputWidget'
// import SearchWidget from '@app/Filters/Widgets/SearchWidget'

const WIDGETS_MAP = {
  select: SelectWidget,
  datefield: DateRangeWidget,
};

export default {
  widgetsMap: WIDGETS_MAP,
  components: {
    // Sorting,
  },
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'sortlist',
    'show_all',
  ],
  data() {
    return {
      search: '',
    };
  },
  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
      };
    },
    baseFilters() {
      return this.getAvailableFilters(this.filters);
    },
  },
  mounted() {},
  methods: {
    hideFilterModal() {
      this.$modal.hide('filters-modal');
      this.$body.classList.remove(
        'is-active-filters-modal',
        'vm--block-scroll'
      );
    },
    getAvailableFilters(filters = []) {
      return filters;
      // .filter(filter => filter.type in this.$options.widgetsMap)
    },
    getMultiValue(key) {
      if (this.value[`${key}_from`] || this.value[`${key}_to`]) {
        return [
          this.value[`${key}_from`] || null,
          this.value[`${key}_to`] || null,
        ];
      }
      return [];
    },
    updateValue(key, value) {
      if (!Array.isArray(value)) {
        this.updateMultipleValues(key, value);
        return;
      }
      this.$emit('input', { ...this.value, [key]: value });
    },
    updateMultipleValues(key, values = {}) {
      console.log(values, 111);
      const res = {};
      const [min, max] = values;
      const fromKey = `${key}_from`;
      const toKey = `${key}_to`;

      if (min) {
        res[fromKey] = min;
      } else {
        delete this.value[fromKey];
      }

      if (max) {
        res[toKey] = max;
      } else {
        delete this.value[toKey];
      }

      this.$emit('input', { ...this.value, ...res });
    },
  },
};

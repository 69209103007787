<template lang="pug">
include /mixins
+b.ds-section.--bg_white.--bordered.--auth
  validation-observer(ref="validator", v-slot="{ valid, handleSubmit }", slim): form(
    @submit.prevent="handleSubmit(prepareData)"
  )
    ui-preloader(:loading="loading")
    row(justify="between" align="center")
      cell(cols="12 6-lg 5-xl")
        +b.ma--bottom_5.--bottom_7-lg
          +b.H1.ds-caption--size_2lg.--size_2xl-lg.--size_3xxl-xl.--color_boldblack.--fw_bold {{ _('Авторизація') }}
        +b.d-none--from_lg
          +b.P.ds-caption--color_boldblack.--size_xs.--size_2sm-sm--weight_regular {{ infoText }}
        row(appearance="spaced", space="3xl")
          validation-provider.relative(
              v-slot="{ errors }"
              name="forbidden",
              slim
            )
              cell.relative(
                cols="12"
              )
                +b.ds-caption--size_2xs.--variant_error.--appearance_right(v-if="errors && errors[0]") {{ errors[0] }}
          cell(cols="12")
            d-input(
              :label-text="fields.email.label",
              vid="email",
              :placeholder="fields.email.placeholder",
              rules="required|email",
              type="email",
              v-model="form.email",
              :disabled="loading"
            )

          cell(cols="12")
            d-input(
              :label-text="fields.password.label",
              vid="password",
              autocomplete="current-password",
              rules="required",
              :type="fields.password.type",
              v-model="form.password",
              :disabled="loading"
            )
              template(#append)
                +b.change-type(@click.prevent="changeType('password')")
                  icon-eye(v-if="'password' === fields.password.type")
                  icon-eye-off(v-else)
          cell(cols="12")
            +b.recaptcha-rules
              +b.P.ds-caption--size_xs.--color_boldblack.--fw_medium Цей сайт захищено технологією reCAPTCHA, до неї застосовуються #[+b.A.ds-caption--size_xs.--color_blue.--fw_medium.--hover_greyblue(href='https://policies.google.com/privacy', target='_blank', rel='noopener nofollow noindex noreferrer') Політика конфіденційності ] й #[+b.A.ds-caption--size_xs.--color_blue.--fw_medium.--hover_greyblue(href='https://policies.google.com/terms', target='_blank', rel='noopener nofollow noindex noreferrer') Умови використання ] Google.
          cell(cols="12")
            control-button(:disabled="loading", appearance="full", type="submit") {{ _('Вхід') }}
          cell.ma--top_6(cols="12")
            row(appearance="spaced", space="xl", align="center")
              cell(cols="12-till-xs")
                bem-text.w--100.d--block(
                  tag='p'
                  color="boldblack"
                  size="2sm"
                  weight="regular"
                  :appearance="['center-till-xs']"
                ) {{_('Забули пароль?')}}
              cell(cols="12-till-xs")
                +b.B.pointer.ds-caption--color_semiblue.--size_2sm.--weight_regular.--hover_boldblack.--appearance_center-till-xs(
                  @click.prevent="modalOpener(openSpecifyEmailModal())"
                ) {{_('Відновити пароль')}}
      cell.d-none--till_lg(cols="6")
        +b.ma--bottom_5.pa--left_8.--left_10-xl.--right_8
          +b.P.ds-caption--color_boldblack.--size_sm.--weight_bold {{ infoText }}
        +b.IMG.d--block.hero--auth(:src="'/static/img/auth-login.svg'")
</template>

<script>
import FormMixin, {
  defaultValidatorErrorsParser,
} from '@app/Forms/FormMixin.js';
import { modalOpener } from '@utils/helpers.js';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import CaptchaMixin from '@app/Forms/CaptchaMixin';
import { loginResource } from '@api/auth.service';

const SpecifyEmail = () => import('./SpecifyEmail.vue');

export default {
  name: 'login',
  mixins: [FormMixin, LoadingStateMixin, CaptchaMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      confirmPassword: null,
      infoText: this._(
        'Реєстрація учасників у особистому кабінеті здійснюється адміністратором після підписання договору. Щодо співпраці просимо надсилати на електронну адресу power@eru.com.ua або звертатися за номером телефону +38 (068) 06 92 804'
      ),
      non_field_errors: '',
      fields: {
        email: {
          label: this._('Еmail'),
          placeholder: this._('mail@gmail.com'),
        },
        password: {
          type: 'password',
          label: this._('Пароль'),
        },
      },
    };
  },
  methods: {
    modalOpener,
    openSpecifyEmailModal() {
      return {
        props: {
          isComponent: SpecifyEmail,
        },
      };
    },
    changeType(field) {
      if ('password' === this.fields[field].type) {
        this.fields[field].type = 'text';
      } else {
        this.fields[field].type = 'password';
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.non_field_errors = parsed.non_field_errors;
      this.$refs.validator.setErrors(parsed);
    },
    async prepareData() {
      this.$refs.validator.validate().then(async valid => {
        if (!valid) return;
        await this.recaptcha();
        this.submit(valid, this.form);
      });
    },
    send(data) {
      return loginResource.execute({}, data).then(res => {
        if (200 === res.code) {
          window.location.href = '/cabinet';
        }
      });
    },
  },
};
</script>

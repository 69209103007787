/* eslint-disable camelcase */
import { commonDataResource } from '@api/organization.service';

const SET_COMMON_DATA = 'SET_COMMON_DATA';

export const commonData = {
  namespaced: true,
  state: {
    organization: {
      isLoaded: false,
      publicRecaptcha: '',
      termsOfUse: '',
      privacyPolicy: '',
      plansPagination: null,
      templatePath: '',
      logo: '',
      favicon: '',
      phones: [],
      emails: [],
      addresses: [
        {
          address: '',
        },
      ],
    },
  },
  actions: {
    async getCommonData({ commit }) {
      const { data } = await commonDataResource.execute();
      commit(SET_COMMON_DATA, data);
    },
  },
  mutations: {
    [SET_COMMON_DATA](state, data) {
      Object.keys(state.organization).forEach(name => {
        state.organization[name] = data.item[name];
        state.organization.isLoaded = true;
      });
      window.faviconUrl = data.item.favicon;
      window.CAPTCHA_KEY = data.item.publicRecaptcha;
      console.log(window.CAPTCHA_KEY);
    },
  },
};

<template lang="pug">
include /mixins
+b.FOOTER.footer
  +b.g-container--variant_tiny
    +b.g-row--align_center.--appearance_column-till-lg.--appearance_spaced.--space_2xl.--space_3xl-lg
      +b.g-cell.g-cols.ma--right_8-lg
        bem-link(
          tag="a"
          color="white"
          size="sm"
          weight="medium"
          hover="text-active"
          :href="organization.termsOfUse"
        ) {{_('Умови використання')}}
      +b.g-cell.g-cols.ma--right_8-lg
        bem-link(
          tag="a"
          color="white"
          size="sm"
          weight="medium"
          hover="text-active"
          :href="organization.privacyPolicy"
        ) {{_('Політика конфіденційності')}}
      +b.g-cell.g-cols
        +b.ROUTER-LINK.ds-caption--color_white.--weight_medium.--hover_greyblue.--size_sm.--hover_text-active.--td_none(
            to="/cabinet/support"
          ) {{ _('Підтримка') }}

</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Footer',
  data() {
    return {};
  },
  computed: {
    ...mapState('commonData', ['organization']),
  },
};
</script>

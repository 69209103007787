<template lang="pug">
include /mixins
+b.confirm
  .vm-modal-wrap
    +b.g-row.--appearance_column.--appearance_spaced.--align_center.--space_lg
      +b.g-cell
        +b.ds-caption--size_2md.--color_boldblue.--appearance_center.H2 {{ question }}
      +b.g-cell
        +b.g-row.--align_center.--justify_between.--space_xl
          +b.g-cell.ma-bottom_9
            control-button(
              size='md'
              appearance="full",
              @click.prevent="submitForm(true)"
              ) {{ _('Yes') }}
          +b.g-cell
            control-button(
              size='md'
              appearance="full",
              @click.prevent="submitForm(false)"
              ) {{ _('No') }}
  button.vm-modal-close-button(
    type="button",
    :aria-label="_('Close')",
    @click="$emit('close')",
  ): span(aria-hidden="true") &times;
</template>
<script>
export default {
  name: 'Confirm',
  props: ['promise', 'question'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    submitForm(value) {
      this.promise(value);
      this.$emit('close');
    },
  },
};
</script>

/* eslint-disable camelcase */
import { getProfileDetail } from '@api/cabinet.service';

const SET_PERSONAL_DATA = 'SET_PERSONAL_DATA';

export const user = {
  namespaced: true,
  state: {
    personal: {
      firstName: '',
      lastName: '',
      email: null,
      phone: null,
      role: '',
      originalCode: null,
      legalEntityTitle: null,
      contractTitle: null,
      signingDate: null,
    },
  },
  actions: {
    async getPersonalData({ commit }) {
      const { data } = await getProfileDetail.execute();
      commit(SET_PERSONAL_DATA, data);
    },
  },
  mutations: {
    [SET_PERSONAL_DATA](state, data) {
      Object.keys(state.personal).forEach(name => {
        state.personal[name] = data.item[name];
      });
    },
  },
};

import FilterController from '@app/Filters/Controller.vue';
import {
  FILTERS_SHORTEN,
  FILTERS_EXTENDED,
  FILTERS_ALL,
  omitExtended,
} from '@app/Filters/const';

export default {
  props: [
    'value',
    'allProps',
    'availableProps',
    'pagination',
    'needFilters',
    'needSorting',
    'needSearch',
    'sortlist',
    'label',
  ],

  components: {
    FilterController,
  },

  data() {
    const allFilters = FILTERS_EXTENDED.some(
      key => this.value.filters &&
        key in this.value.filters &&
        this.value.filters[key]
    );
    return {
      allFilters,
      displayedFilters: this.getDisplayedFilters(allFilters),
    };
  },
  computed: {
    getTransformedAvailable() {
      const result = (this.availableProps && this.availableProps.result) || [];
      const available = ((result && result.items) || []).reduce((acc, item) => {
        acc[item.slug] = item.options;
        return acc;
      }, {});
      return available;
    },
  },
  methods: {
    handleFilter(parameters) {
      this.$emit('input', this.clearExtendedFilters(parameters));
    },

    toggleAllFilters() {
      this.setFiltersAvailability(!this.allFilters);
    },

    getDisplayedFilters(all) {
      return all ? FILTERS_ALL : FILTERS_SHORTEN;
    },

    clearExtendedFilters(parameters) {
      if (this.allFilters) {
        return parameters;
      }

      const { filters = {}, ...rest } = parameters;
      console.log(parameters, filters);
      return { filters: omitExtended(filters), ...rest };
    },

    setFiltersAvailability(all) {
      this.allFilters = all;
      this.displayedFilters = this.getDisplayedFilters(this.allFilters);
    },
  },
};

import { partialRight } from 'ramda'

import {
  baseResource as superResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import {
  xCSRFMiddleware,
  multipartRequestMiddleware,
  jsonTransformer,
  defaultDataTransformer,
  raiseHttpErrorTransformer,
  superagentJsonTransformer,
} from '@aspectus/resource-commons'

import makeTemplateGetter from '@aspectus/resource-template-url-getter';

import fetcher from '@aspectus/superagent-fetch';

export const baseResource = superResource
  .middleware(xCSRFMiddleware)
  .transform(raiseHttpErrorTransformer)

export const receiveResource = baseResource
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)

export const sendResource = baseResource
  .middleware(jsonRequestMiddleware)
  .transform(jsonTransformer)
  .config('method', 'POST')

export const simpleSendResource = baseResource
  .config('method', 'POST')

export const updateResource = sendResource
  .config('method', 'PATCH')

export const replaceResource = sendResource
  .config('method', 'PUT')

export const destroyResource = baseResource
  .config('method', 'DELETE')

export const optionsResource = receiveResource
  .config('method', 'OPTIONS')

export const sendFileResource = baseResource
  .fetcher(fetcher)
  .middleware(multipartRequestMiddleware)
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .config('method', 'POST');

export const updateWithFile = sendFileResource
  .config('method', 'PATCH');

export const createResource = (template, base = baseResource) => (
  base.url(makeTemplateGetter(template))
)
export const createReceiver = partialRight(createResource, [receiveResource])
export const createSender = partialRight(createResource, [sendResource])
export const createUpdater = partialRight(createResource, [updateResource])
export const createReplacer = partialRight(createResource, [replaceResource])
export const createSimpleSender = partialRight(createResource, [simpleSendResource])
export const createFileSender = partialRight(createResource, [sendFileResource])
export const createUpdaterWithFile = partialRight(createResource, [updateWithFile])
export const createOptionsGetter = partialRight(createResource, [optionsResource])
export const createRemover = partialRight(createResource, [destroyResource])

import Confirm from './Confirm';
import UiLogout from './UiLogout.vue';
import UiSlider from './UiSlider';
import UiClickOutside from './UiClickOutside';
import UiPreloader from './UiPreloader';
import SeoText from './SeoText';
import BreadCrumbs from './BreadCrumbs';
import PlanDateNavigation from './PlanDateNavigation';
import UiUploader from './UiUploader';

export default function install(Vue) {
  Vue.component('Confirm', Confirm);
  Vue.component('UiPreloader', UiPreloader);
  Vue.component('UiLogout', UiLogout);
  Vue.component('UiUploader', UiUploader);
  Vue.component('UiSlider', UiSlider);
  Vue.component('SeoText', SeoText);
  Vue.component('UiClickOutside', UiClickOutside);
  Vue.component('BreadCrumbs', BreadCrumbs);
  Vue.component('PlanDateNavigation', PlanDateNavigation);
}

export function checkPermission(to, from, next) {
  const ROLE = window.role;
  const {
    meta: { permissions = [] },
  } = to;
  const isAccessed = permissions.includes(ROLE);
  setTimeout(() => {
    if (isAccessed) {
      next();
    } else {
      next({ name: 'no-access' });
    }
  }, 100);
  if ('administrator' === ROLE || 'manager' === ROLE) {
    if ('cabinet' === to.name || 'personal' === to.name) {
      window.location.href = '/admin/';
    }
  } else if ('anonymous' === ROLE) {
    if ('cabinet' === to.name || 'personal' === to.name) {
      window.location.href = '/auth/login';
    }
  }
}

<template lang="pug">
  include /mixins
  div.rel
    .input-label {{ _('Додаткова угода') }}
    d-control-static-multiselect(
      :multiple='multiple',
      :options='filter.agreements || []'
      v-model='checked'
      :name='filterName'
      track-by='id'
      :label="filterLabel"
      :disabled="disabled"
      :searchable="searchable"
      :close-on-select="closeOnSelect"
      @input='submit'
      :maxHeight="224"
      :placeholder="needDisplayPlaceholder && filter.title ? filter.title : _('Все')"
    )
</template>

<script>
export default {
  name: 'SelectWidget',
  props: {
    value: {},
    filter: {},
    filterName: {},
    disabled: {
      default: false,
    },
    filterLabel: {
      default: 'title',
    },
    searchable: {
      default: false,
    },
    multiple: {
      default: false,
    },
    needDisplayPlaceholder: {
      default: true,
    },
    closeOnSelect: {
      default: true,
    },
  },

  data() {
    return {
      checked: '',
    };
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval);
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value);
  },

  methods: {
    submit() {
      const val = this.normalizeTo(this.checked);
      this.$emit('input', val);
    },

    normalizeTo(value) {
      const val = this.getValue(value);

      const checked = this.filter.agreements
        .filter(el => val.find(v => v.id === el.id))
        .map(el => el.id);
      return checked;
    },

    normalizeFrom(value) {
      const val = this.getValue(value);

      const checked = this.filter.agreements.filter(el => val.find(v => v.toString() === el.id.toString()));
      return checked;
    },

    getValue(value) {
      let val = value;

      if (!value) return [];

      if (!Array.isArray(value)) {
        val = [value];
      }

      return val;
    },
  },
};
</script>

import { pick, omit } from 'ramda';
import { renderSlim } from '@aspectus/vue-utils';

const PAGINATION_KEYS = ['limit', 'offset', 'count', 'total'];
const FILTER_PAGINATION_DROP_KEYS = ['offset'];

export default {
  name: 'pagination-controller',

  props: {
    receiver: {
      type: Function,
      required: true,
    },
    receiverAdditional: {
      type: Function,
    },
    parameters: Object,
    resultPagination: {},
    paginationKeys: {
      type: Array,
      default: () => PAGINATION_KEYS,
    },
    filterPaginationDropKeys: {
      type: Array,
      default: () => FILTER_PAGINATION_DROP_KEYS,
    },
  },

  data() {
    return {
      filters: {},
      pagination: {},
    };
  },

  watch: {
    parameters: {
      immediate: true,
      handler(value = {}) {
        const filters = omit(this.paginationKeys, value);
        const pagination = {
          ...this.pagination,
          ...pick(this.paginationKeys, value),
        };

        this.filters = filters;
        this.pagination = pagination;
      },
    },
    resultPagination: {
      immediate: true,
      handler(value = {}) {
        this.pagination = { ...this.pagination, ...value };
      },
    },
  },

  methods: {
    update(parameters) {
      const params = { ...parameters };
      if (!params.offset) {
        delete params.offset;
      }
      this.$emit('update:parameters', params);

      if (params.filters.agreement && params.filters.agreement.length) {
        this.receiverAdditional(params);
      } else {
        this.receiver(params);
      }
    },

    changeFilters(filters) {
      this.update({
        ...pick(this.paginationKeys, this.pagination),
        ...filters,
      });
    },

    changePagination(pagination) {
      this.update({
        ...pick(this.paginationKeys, pagination),
        ...this.filters,
      });
      this.scrollTop();
    },

    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },

  render(h) {
    return renderSlim(
      this.$scopedSlots.default({
        filters: this.filters,
        changeFilters: this.changeFilters,
        pagination: this.pagination,
        changePagination: this.changePagination,
      }),
      h,
      'tag'
    );
  },
};

export const mapDots = number => {
  function MapRender() {
    const dots = [...document.getElementsByClassName('region-marker')]
    const map = document.getElementById('ukraine-map')
    const PERFECT_MOB_WIDTH = 60
    const PERFECT_DESKTOP_WIDTH = 84
    const BREAKPOINT = 768
    if (map) {
      const k = map.clientWidth / map.width.animVal.value
      const regions = map.getElementsByClassName('land')
      regions.forEach(e => e.classList.remove('is-active'))
      dots.forEach(dot => {
        dot.classList.remove('is-active')
        const {
          region,
          slide,
        } = dot.dataset
        // eslint-disable-next-line eqeqeq
        if (slide != number) return
        const parent = document.getElementById(region)
        const position = parent.getBBox()
        const left = (position.x + (position.width / 2)) * k
        const top = (position.y + (position.height / 2)) * k
        dot.classList.add('is-active')
        dot.style.setProperty('--left', `${left}px`)
        dot.style.setProperty('--top', `${top}px`)
        const MOBILE_WIDTH = PERFECT_MOB_WIDTH
        const DESKTOP_WIDTH = PERFECT_DESKTOP_WIDTH > position.width ? PERFECT_DESKTOP_WIDTH : position.width
        const w = window.innerWidth > BREAKPOINT ? DESKTOP_WIDTH : MOBILE_WIDTH
        dot.style.setProperty('--width', `${w}px`)
        dot.getElementsByTagName('svg')[0].setAttribute('viewBox', `0 0 ${w} ${w}`)
        dot.getElementsByTagName('circle')[0].setAttribute('r', `${w / 2}`)
        // dot
        parent.addEventListener('mouseenter', () => {
          parent.classList.add('is-hovered')
          dot.classList.add('is-hovered')
        })
        parent.addEventListener('mouseleave', () => {
          parent.classList.remove('is-hovered')
          dot.classList.remove('is-hovered')
        })
        parent.addEventListener('click', () => {
          window.location.href = dot.href
        })
      })
    }
  }
  MapRender()
  window.addEventListener('orientationchange', () => {
    setTimeout(() => {
      MapRender()
    }, 300);
  });
}

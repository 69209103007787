<template lang="pug">
  include /mixins
  +b.sorting
    native-sorting.d-none--from_xl(
      :values='values',
      :init-value='checked',
      @change='setValueFromNative'
    )
    +b.g-row--align_center.d-none--till_xl
      +b.g-cell.g-cols
        d-select(
          :options="values"
          @input="submit"
          v-model="checked"
          track-by="key"
          label="title"
          :allow-empty="false"
        )

</template>

<script>
const NativeSorting = () => import('@app/Filters/NativeSorting');

export default {
  name: 'Sorting',
  components: {
    NativeSorting,
  },
  props: [
    'disabled', 'value', 'filters',
  ],
  data() {
    return {
      values: [
        {
          title: this._('Ascending price'),
          key: 'price',
        },
        {
          title: this._('Descending price'),
          key: '-price',
        },
      ],
      checked: {},
      show: false,

    };
  },

  watch: {
    value(nval) {
      this.checked = this.normalize(nval);
    },
  },
  mounted() {
    this.checked = this.normalize(this.value);
  },
  methods: {
    setValueFromNative(key) {
      this.checked = this.values.find(({ key: k }) => k === key);
      this.submit()
    },
    submit() {
      this.$emit('input', [this.checked.key]);
    },
    normalize(value) {
      if (!value) return [];
      const checked = this.values.find(el => {
        if (el.key === value[0]) {
          return el;
        }
        return false;
      });
      return checked;
    },
  },
};
</script>

<template lang="pug">
include /mixins
div
  +b.g-row.--align_center.--space_lg
    +b.g-cell(v-if="isPrevDay")
      a(:href="`${currentPage}${previousPage}`")
        icon-arrow-left
    +b.g-cell
      bem-text(
        tag="p"
        color="blue"
        size="sm"
        weight="bold"
        appearance="capitalize"
      ) {{ currentDate }}
    +b.g-cell(v-if="isNextDay")
      a(:href="`${currentPage}${nextPage}`")
        icon-arrow-right

</template>

<script>
export default {
  name: 'PlanDateNavigation',
  props: {
    currentDate: {
      type: String,
    },
    previousPage: {
      value: [Number, String],
    },
    currentPage: {
      type: String,
    },
    nextPage: {
      value: [Number, String],
    },
    isNextDay: {
      value: Boolean,
    },
    isPrevDay: {
      value: Boolean,
    },
  },
};
</script>

import VueRouter from 'vue-router';
import { prefixLanguage } from '@utils/urls';
import { checkPermission } from './route-validation';

const ROUTES_NAMES = {
  /* Cabinet */
  CABINET: 'cabinet',
  CABINET_PLANS: 'cabinet_plans',
  PERSONAL: 'personal',
  SUPPORT: 'support',

  /* Auth */
  AUTH: 'auth',
  LOGIN: 'login',
  REGISTRATION: 'registration',
  SET_PASSWORD: 'set_password',
  CHANGE_PASSWORD: 'change_password',
  PASSWORD_RECOVERY_REQUEST: 'password_recovery_request',

  /* Plan pages */
  PLANS: 'plans',
  ALL_PLANES: 'all_plans',
  PLAN: 'plan',
  PLAN_EDIT_DAY: 'plan_edit_day',
  PLAN_EDIT_MONTH: 'plan_month',

  /* Errors */
  NO_ACCESS: 'no-access',
  NOT_FOUND: 'not-found',

  /* Text pages */
  TEXT_PAGE: 'text_page',
};

export { ROUTES_NAMES };

const ROUTES = [
  {
    path: prefixLanguage('/'),
    beforeEnter: checkPermission,
    redirect: { name: ROUTES_NAMES.PERSONAL },
    meta: {
      permissions: ['contractor', 'anonymous'],
      breadCrumb: $this => $this._('Home'),
    },
  },
  {
    name: ROUTES_NAMES.CABINET,
    path: prefixLanguage('/cabinet/'),
    component: () => import('@app/Base/CabinetLayout'),
    redirect: { name: ROUTES_NAMES.PERSONAL },
    beforeEnter: checkPermission,
    meta: { permissions: ['contractor', 'anonymous'] },
    children: [
      {
        component: () => import('@app/Personal/Cabinet/Profile'),
        name: ROUTES_NAMES.PERSONAL,
        path: prefixLanguage('personal'),
        beforeEnter: checkPermission,
        meta: { permissions: ['contractor'], cabinet: true },
      },
      {
        component: () => import('@app/Personal/Cabinet/Support'),
        name: ROUTES_NAMES.SUPPORT,
        path: prefixLanguage('support'),
        beforeEnter: checkPermission,
        meta: { permissions: ['contractor'] },
      },
    ],
  },
  {
    name: ROUTES_NAMES.PLANS,
    path: prefixLanguage('/plans/*'),
    component: () => import('@app/Personal/Plans/AllPlans'),
    beforeEnter: checkPermission,
    meta: { permissions: ['contractor'] },
  },
  {
    component: () => import('@app/Personal/Plans/Plan'),
    name: ROUTES_NAMES.PLAN,
    path: prefixLanguage('/plan/:id/'),
    beforeEnter: checkPermission,
    meta: { permissions: ['contractor'] },
  },
  {
    component: () => import('@app/Personal/Plans/EditDay'),
    name: ROUTES_NAMES.PLAN_EDIT_DAY,
    path: prefixLanguage('/change/day/:id/'),
    beforeEnter: checkPermission,
    meta: { permissions: ['contractor'] },
  },
  {
    component: () => import('@app/Personal/Plans/EditMonth'),
    name: ROUTES_NAMES.PLAN_EDIT_MONTH,
    path: prefixLanguage('/change/month/:id'),
    beforeEnter: checkPermission,
    meta: { permissions: ['contractor'] },
  },
  {
    component: () => import('@app/Forms/AuthTemplate'),
    name: ROUTES_NAMES.AUTH,
    path: prefixLanguage('/auth/'),
    redirect: { name: ROUTES_NAMES.LOGIN },
    meta: {
      permissions: ['anonymous'],
      breadCrumb: $this => $this._('auth'),
    },
    children: [
      {
        component: () => import('@app/Forms/Auth/Login'),
        name: ROUTES_NAMES.LOGIN,
        path: prefixLanguage('login'),
        beforeEnter: checkPermission,
        meta: { permissions: ['anonymous'] },
      },
      {
        component: () => import('@app/Forms/Auth/ChangePassword'),
        name: ROUTES_NAMES.CHANGE_PASSWORD,
        path: prefixLanguage('change_password'),
        beforeEnter: checkPermission,
        meta: {
          permissions: ['contractor'],
          breadCrumb: $this => $this._('test'),
        },
      },
      {
        component: () => import('@app/Forms/Auth/ResetPassword'),
        name: 'reset_password',
        path: prefixLanguage('reset_password'),
        beforeEnter: checkPermission,
        meta: {
          permissions: ['contractor'],
          breadCrumb: $this => $this._('reset_password'),
        },
      },
      {
        component: () => import('@app/Forms/Auth/ChangePassword'),
        name: ROUTES_NAMES.PASSWORD_RECOVERY_REQUEST,
        path: prefixLanguage('password_recovery_request'),
        beforeEnter: checkPermission,
        meta: {
          permissions: ['contractor'],
          breadCrumb: $this => $this._('test'),
        },
      },
    ],
  },
  {
    component: () => import('@app/TextPage/Layout'),
    path: prefixLanguage('/page/:slug'),
    beforeEnter: checkPermission,
    name: ROUTES_NAMES.TEXT_PAGE,
    meta: {
      permissions: ['contractor', 'administrator', 'manager'],
      // breadCrumb: ($this) => $this._('Home'),
    },
  },
  {
    component: () => import('@app/error-pages/403'),
    path: prefixLanguage('/no-access/'),
    name: ROUTES_NAMES.NO_ACCESS,
  },
  {
    component: () => import('@app/error-pages/404'),
    path: prefixLanguage('*/*'),
    name: ROUTES_NAMES.NOT_FOUND,
  },
];

export default new VueRouter({
  mode: 'history',
  routes: ROUTES,
});

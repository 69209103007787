import Inputs from './inputs';
import Described from './described';
import Select from './Select';
import FloatLabel from './FloatLabel';
import Buttons from './Buttons';
import Datepicker from './Datepicker';

function install(Vue) {
  Vue.use(Inputs);
  Vue.use(Described);
  Vue.use(Buttons);

  Vue.component('d-control-static-multiselect', Select);
  Vue.component('d-control-static-datepicker', Datepicker);
  Vue.component('float-label', FloatLabel);
}

export default { install };

<template lang="pug">
url-parameters-controller(
  @history-change="manualHistoryChangeHandler",
  :initial="initial",
  :base-url="baseUrl",
  :to="parametersTo",
  :from="parametersFrom",
  v-slot="{ parameters, changeParameters }"
)
  paginated-resource-controller(
    :resource="productsResource",
    :resource-additional="productsAdditionalResource",
    :parameters="parameters",
    :changeParameters="changeParameters",
    :label="label",
    v-slot="paginatedProps"
  )
    component(
      :is="listController",
      ref="controller",
      :attributes-resource="attributesResource",
      v-bind="{...$attrs, ...paginatedProps }",
      :need-filters="needFilters",
      :need-sorting="needSorting",
      :need-search="needSearch",
      :label="label",
      :sortlist="sortlist || []",
      :scroll-paginate="scrollPaginate",
    )
      template(v-slot:[name]="scope", v-for="(name, key) in eSlots")
        slot(v-bind="scope", :name="key" :items="paginatedProps.result ? paginatedProps.result.items : []")
</template>

<script>
import {
  queryStringParser,
  parsePaginationUrl,
} from '@app/Filters/transformers';

import Controller from '@app/List/Controller.vue';

const eSlotsResolver = (acc, key) => {
  acc[key] = key;
  return acc;
};

const getESlots = keys => keys.reduce(eSlotsResolver, {});

export default {
  components: { Controller },
  props: {
    initial: {},
    baseUrl: {},
    productsResource: {},
    productsAdditionalResource: {},
    attributesResource: {},
    // availableAttributesResource: {},
    needFilters: {},
    needSorting: {},
    needSearch: {},
    scrollPaginate: {},
    label: {},
    sortlist: {},
    kwargs: {},
    listController: Controller,
  },
  data() {
    return {};
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots));
    },
  },
  mounted() {},
  methods: {
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        ...this.kwargs,
      };
    },

    parametersFrom({ ...rest }) {
      const search = decodeURIComponent(window.location.search);
      const data = queryStringParser(search);
      const query = parsePaginationUrl();
      const pagination = {
        limit: query.limit || this.initial.limit,
        offset: query.offset || 0,
      };

      return {
        ...rest,
        filters: data,
        label: this.label,
        ...pagination,
      };
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search);
      }
    },
  },
};
</script>

/* eslint-disable */
/**
 * return tree view ,
 * set 4 spaces before initital string if it has depth
 * @param {string} string - text in select label
 * @param {number} by - depth
 */
import Confirm from '@app/UiElements/Confirm';

// import Vue from 'vue'
// import Inputmask from 'inputmask'

const NOT_DEPTH = 1;
const IDENTS_SIZE = 4;
const INIT_VAL = 0;

export function outstand(string, by) {
  if (by < NOT_DEPTH) {
    return string;
  }

  const whitespace = '\u00A0'.repeat(IDENTS_SIZE);
  const marker = '';
  // const marker = '\uFE42'
  // const marker = '\u232A'

  return whitespace.repeat(by) + (by > INIT_VAL ? marker : '') + string;
}

export function getMaxOfArray(numArray) {
  return Math.max.apply(null, numArray);
}

export function confirmWithPromise(question, $this) {
  return new Promise((res, rej) => {
    const pr = (arg) =>
      new Promise((resolve, reject) => {
        if (true === arg) {
          resolve(arg);
        } else {
          reject(new Error('...'));
        }
      }).then(
        (result) => {
          res(result);
        },
        () => {
          rej();
        }
      );
    $this.$modal.show(
      Confirm,
      {
        question,
        promise: pr,
      },
      {
        height: 'auto',
        resizable: true,
      }
    );
  });
}

/**
 * @param {string} path - name of key(
 *  f.e. Object is -{key: {child_key: 'test'}}, path - key, child_key
 * )
 */
export function pathGetter(path) {
  if (!Array.isArray(path)) {
    path = Array.from(arguments);
  }

  return (item) => path.reduce((acc, x) => (acc ? acc[x] : '-'), item);
}

export class Field {
  constructor(key, label = null, getter = null, parameters = {}) {
    if (null !== label && is.function(label)) {
      label = null;
      getter = label;
      parameters = getter;
    }

    if (null !== getter && is.not.function(getter)) {
      getter = null;
      parameters = getter;
    }

    this.key = key;
    this.label = label || this.key.toTitle();
    this.getter = getter || pathGetter(this.key);
  }

  getValue(obj) {
    return this.getter(obj);
  }
}

// export function mask(inputObject, number) {
//   if (inputObject.selectionStart) {
//     inputObject.setSelectionRange(number, number)
//     inputObject.focus()
//   }
//   const phonemask = new Inputmask({ mask: '+38(999)999-9999', greedy: false })
//   phonemask.mask(inputObject)
// }

// export function masking(name, $this) {
//   //  console.log($this.$refs)
//   let zero = 0
//   let reg = /\d+/
//   let events = ['click', 'focus', 'keyup']
//   events.forEach(event => {
//     let root = $this.$refs[name]
//     //  console.log(root);
//     if (!root) return
//     if (root[0]) {
//       root = root[0]
//     }
//     root.$el.addEventListener(event, e => {
//       let numb = e.target.value.match(reg)
//       if (numb) {
//         mask(e.target, numb[zero].length)
//       } else {
//         mask(e.target, zero)
//       }
//     })
//   })

// }

/**
 * simple debounce
 * @param {function} f - fn for callback
 * @param {number} ms - time for delay
 */
export function debounce(f, ms) {
  let isCooldown = false;

  // eslint-disable-next-line func-names
  return function () {
    //  console.log(isCooldown, '@#@#@#@##')
    if (isCooldown) return;

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => {
      isCooldown = false;
      return false;
    }, ms);
  };
}

export function getId(url) {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  // console.log(match)
  const eleven = 11;
  if (match && match[2].length === eleven) {
    return match[2];
  }
  return new Error('error parsed link');
}

import ModalBaseTemplate from '@app/UiElements/Modals/ModalTemplate.vue';

// use function as method in vue component
export function modalOpener({
  modal = ModalBaseTemplate,
  props = {},
  reaction = () => {},
  value = undefined,
  classes = [],
  config = {},
}) {
  const promise = (arg) =>
    new Promise((resolve, reject) => {
      if (arg !== undefined) {
        resolve(arg);
        return;
      }
      reject();
    })
      .then((result) => {
        reaction(value || result);
      })
      .catch((err) => {
        console.warn('helpers.js', err);
      });
  this.$modal.show(
    modal,
    { ...props, promise },
    {
      classes,
      ...config,
    }
  );
}

<template lang="pug">
include /mixins
+b.ds-section.--bg_white.--bordered.--auth
  validation-observer(ref="validator", v-slot="{ valid, handleSubmit }", slim): form(
  @submit.prevent="handleSubmit(prepareData)"
)
    ui-preloader(:loading="loading")
    row(justify="between" align="center")
      cell(cols="12 6-lg 5-xl")
        +b.ma--bottom_5.--bottom_7-lg
          +b.H1.ds-caption--size_2lg.--size_2xl-lg.--size_3xxl-xl.--color_boldblack
            span.ds-caption--weight_bold {{ _('Створення пароля ') }}
            span.ds-caption--weight_regular {{ _('для облікового запису') }}
        row(appearance="spaced" space="3xl")
          validation-provider.relative(
              v-slot="{ errors }"
              name="forbidden",
              slim
            )
              cell.relative(
                cols="12"
                v-if="errors && errors[0]"
              )
                +b.ds-caption--size_2xs.--variant_error.--appearance_right {{ errors[0] }}
          cell(cols="12")
            d-input(
              :label-text="fields.password.label",
              vid="password",
              rules="password|required",
              :type="fields.password.type",
              v-model="form.password",
              :disabled="loading"
            )
              template(#append)
                +b.change-type(@click.prevent="changeType('password')")
                  icon-eye(v-if="'password' === fields.password.type")
                  icon-eye-off(v-else)
          cell(cols="12")
            d-input(
              :label-text="fields.passwordConfirm.label",
              vid="passwordConfirm",
              rules="required|confirmed:password",
              :type="fields.passwordConfirm.type",
              v-model="form.passwordConfirm",
              :disabled="loading"
            )
              template(#append)
                +b.change-type(@click.prevent="changeType('passwordConfirm')")
                  icon-eye(v-if="'password' === fields.passwordConfirm.type")
                  icon-eye-off(v-else)
          cell(cols="12")
            control-button(:disabled="loading", appearance="full", type="submit") {{ _('Створити') }}
      cell.d-none--till_lg(cols="6")
        +b.IMG.d--block.hero--auth(:src="'/static/img/auth-create.svg'")

</template>
<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { passwordConfirmResource } from '@api/auth.service';

export default {
  name: 'SetPassword',
  mixins: [FormMixin, LoadingStateMixin],
  props: {
    crypt: {
      type: String,
      required: true,
      default: '',
    },
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
        idCrypt: this.crypt,
        userToken: this.token,
      },
      fields: {
        password: {
          type: 'password',
          label: this._('Новий пароль'),
        },
        passwordConfirm: {
          type: 'password',
          label: this._('Повторіть новий пароль'),
        },
      },
    };
  },
  mounted() {
    console.log(this.crypt, this.token);
  },
  methods: {
    changeType(field) {
      if ('password' === this.fields[field].type) {
        this.fields[field].type = 'text';
      } else {
        this.fields[field].type = 'password';
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.non_field_errors = parsed.non_field_errors;
      this.$refs.validator.setErrors(parsed);
    },
    async prepareData() {
      this.$refs.validator.validate().then(async valid => {
        if (!valid) return;
        this.submit(valid, this.form);
      });
    },
    send(data) {
      return passwordConfirmResource.execute({}, data).then(res => {
        if (200 === res.code) {
          window.location.href = '/auth/login';
        }
      });
    },
  },
};
</script>

import { baseResource, createReceiver, createSender } from '@resource/resource';

import { prefixAPI } from '@resource/api';

import { friendlyUrlGenerator } from '@aspectus/vue-friendly';

const MODEL = 'cabinet';

const order = {
  path: ['prefix', 'label', 'filters', 'page'],
};

const PROFILE_DETAIL_URL = prefixAPI('/profile/detail/', MODEL);
const PROFILE_UPDATE_URL = prefixAPI('/profile/update/', MODEL);
const ORDER_DETAIL_URL = prefixAPI('/profile/order/{id}/', MODEL);
const ORDERS_LIST_URL = '/cabinet/orders-ajax/';

export const getProfileDetail = createReceiver(PROFILE_DETAIL_URL);
export const getOrderDetail = createReceiver(ORDER_DETAIL_URL);
export const updateProfileData = createSender(PROFILE_UPDATE_URL);
export const getOrderList = friendlyUrlGenerator(
  [ORDERS_LIST_URL],
  baseResource,
  order
);

import { mergeContext } from '@aspectus/vue-utils';
import { generateModifierClassNames } from '@aspectus/bem';

const b = 'control-select';

export const normalizeSelectValue = value => (Array.isArray(value) || 'undefined' === typeof value || null === value
  ? value || []
  : [value]);

export function getLabel(option, key, customGetter) {
  if (!option) return '';

  if (Object(option) === option) {
    if (customGetter) {
      return customGetter(option);
    }

    return option[key];
  }

  return option;
}

export function multipleLabel(h, context, props) {
  return h(
    'span',
    {
      attrs: {
        class: 'multiselect__single',
      },
    },
    [
      (props.values &&
        props.values.length &&
        props.values
          .map(option => getLabel(
            option,
            context.props.label,
            context.data.attrs.customLabel
          ))
          .join(', ')) ||
        '',
    ]
  );
}

const emptyPlaceholder = {};

export default {
  name: b,
  functional: true,

  props: {
    size: { default: 'md' },
    styling: { default: 'default' },
    variant: { default: 'select' },
    'show-selected': { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    preselectFirst: { type: Boolean, default: false },
    label: { type: String, default: 'title' },
    labelText: { type: String },
    'float-label': {},
    value: {},
    required: {},
    placeholder: { default: '' },
    noOptions: { default: () => emptyPlaceholder },
    noResult: { default: () => emptyPlaceholder },
    maxElements: { default: () => emptyPlaceholder },
    selectLabel: { default: '' },
    selectedLabel: { default: '' },
    deselectLabel: { default: '' },
    tagPlaceholder: { default: '' },
    closeOnSelect: {},
    counter: { type: Boolean, default: false },
  },

  render(h, context) {
    const {
      size,
      styling: style,
      variant,
      showSelected,
      multiple,
      disabled,
      readonly,
      preselectFirst,
      label,
      labelText,
      value,
      required,
      placeholder,
      noOptions,
      noResult,
      tagPlaceholder,
      maxElements,
      selectLabel,
      selectedLabel,
      deselectLabel,
      closeOnSelect,
      counter,
    } = context.props;
    const { class: cls, staticClass, ...data } = context.data;

    return h(
      'tag',
      {
        class: [
          cls,
          b,
          generateModifierClassNames(
            b,
            { show_selected: showSelected, multiple, style, size, variant },
            '--',
            '_'
          ),
          { 'is-readonly': readonly, 'is-disabled': disabled },
        ],
        staticClass,
      },
      [
        h('tag', { class: [`${b}__body`] }, [
          labelText
            ? h('control-label', {
              class: [`${b}__label`],
              attrs: {
                'label-text': labelText,
                'is-required': required,
                value,
              },
            })
            : null,

          context.data.scopedSlots && context.data.scopedSlots.prepend
            ? h(
              'div',
              {
                class: [`${b}__prepend`],
              },
              [context.data.scopedSlots.prepend({ value })]
            )
            : null,
          counter && multiple && value && value.length && 1 < value.length
            ? h('tag', { class: [`${b}__counter`] }, [value.length])
            : null,

          h(
            'vue-multiselect',
            mergeContext(data, {
              class: [`${b}__element`],
              attrs: {
                label,
                value,
                selectLabel,
                selectedLabel,
                deselectLabel,
                tagPlaceholder,
                placeholder,
                multiple,
                preselectFirst,
                disabled: readonly || disabled,
                'close-on-select':
                  undefined === closeOnSelect ? !multiple : closeOnSelect,
              },
              on: {
                open: e => data.on && data.on.focus && data.on.focus(e),
                close: e => data.on && data.on.blur && data.on.blur(e),
              },
              scopedSlots: {
                // selection: props => (
                //   props.values && props.values.length
                //   // && !props.isOpen
                //     ? multipleLabel(h, context, props)
                //     : null
                // ),
                ...context.data.scopedSlots,
                noOptions:
                  (context.data.scopedSlots &&
                    context.data.scopedSlots.noOptions) ||
                  (() => (noOptions === emptyPlaceholder
                    ? context.parent._('List is empty.')
                    : noOptions)),
                noResult:
                  (context.data.scopedSlots &&
                    context.data.scopedSlots.noResult) ||
                  (() => (noResult === emptyPlaceholder
                    ? context.parent._('Список пуст')
                    : noResult)),
                maxElements:
                  (context.data.scopedSlots &&
                    context.data.scopedSlots.maxElements) ||
                  (() => (maxElements === emptyPlaceholder
                    ? context.parent._('Список пуст')
                    : maxElements)),
              },
            })
          ),
          labelText
            ? h('float-label', {
              class: [`${b}__label`],
              attrs: {
                'label-text': labelText,
                'is-required': required,
                value,
              },
            })
            : null,
        ]),
      ]
    );
  },
};

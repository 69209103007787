/* eslint-disable */ 
export function progressBar() {
  const ringsData = Array.from(document.getElementsByClassName('progress-ring'))
  const circleData = Array.from(document.getElementsByClassName('progress-ring__circle'))
  const textFields = Array.from(document.getElementsByClassName('ds-caption--output-progress'))

  for (let index = 0; index < ringsData.length; ++index) {
    const ringsElement = ringsData[index]
    console.log(ringsElement);
    const radius = circleData[index].r.baseVal.value;
    const percent = textFields[index].innerHTML
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - percent / 100 * circumference;

    ringsElement.style.strokeDasharray = `${circumference} ${circumference}`;
    ringsElement.style.strokeDashoffset = circumference;
    ringsElement.style.strokeDashoffset = offset;
  }
}

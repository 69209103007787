<template lang="pug">
include /mixins.pug
label(
  v-bem:[blockName].label="{'float': true}"
  :class="[{'control-input__label--active': value || value === 0 }]"
  :for="id",
) {{ labelText }}
  slot
  span(
    v-if="isRequired"
    v-bem.is_required=""
  ) &nbsp;*
  //- {{ !$options.isNil(value) && !$options.isEmpty(value) }}
</template>

<script>

export default {
  name: 'static-label',
  block: 'control-input',
  props: {
    blockName: {
      default: 'control-input',
    },
    labelText: {
      type: String,
    },
    id: {
      type: String,
      required: false,
    },
    value: {},
    isRequired: {
      type: Boolean,
    },
  },
};
</script>

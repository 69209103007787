import {
  createSender,
} from '@resource/resource';

// deleteResource
import { prefixAPI } from '@resource/api';
// import { prefixLanguage } from '@utils/urls'
// import {
//   simpleTransformer,
// } from '@resource/transformer'

const LOGIN_URL = prefixAPI('auth/login/');
const LOGOUT_URL = prefixAPI('auth/logout/');
const REGISTER_URL = prefixAPI('auth/registration/')
const REGISTER_CONFIRM_URL = prefixAPI('auth/registration_confirm/')
const PASSWORD_RESET_URL = prefixAPI('auth/password/recover/')
const PASSWORD_CONFIRM_URL = prefixAPI('auth/password/set/')
const PASSWORD_CHANGE_URL = prefixAPI('auth/password/reset/')

// const EMAIL_VERIFY_REG_SEND = prefixAPI('auth/email-verify/register/send/')
// const EMAIL_VERIFY_REG_CONFIRM = prefixAPI('auth/email-verify/register/confirm/')
const EMAIL_CHANGE_SEND = prefixAPI('auth/change_email/')
const EMAIL_CHANGE_CONFIRM = prefixAPI('auth/change_email_confirm/')
const EMAIL_VERIFY_SOCIAL_SEND = prefixAPI('auth/social_set_email/')
const VALIDATE_OTP = prefixAPI('auth/validate_otp/')

export const loginResource = createSender(LOGIN_URL);
export const logoutResource = createSender(LOGOUT_URL);
export const registerResource = createSender(REGISTER_URL)
export const registerConfirmResource = createSender(REGISTER_CONFIRM_URL)

export const passwordResetResource = createSender(PASSWORD_RESET_URL)
export const passwordConfirmResource = createSender(PASSWORD_CONFIRM_URL)
export const passwordChangeResource = createSender(PASSWORD_CHANGE_URL)

// export const emailVerifyRegSendResource = createSender(EMAIL_VERIFY_REG_SEND)
// export const emailVerifyRegConfirmResource = createSender(EMAIL_VERIFY_REG_CONFIRM)

export const emailVerifyChangeSendResource = createSender(EMAIL_CHANGE_SEND)
export const emailVerifyChangeConfirmResource = createSender(EMAIL_CHANGE_CONFIRM)

export const emailVerifySocialSendResource = createSender(EMAIL_VERIFY_SOCIAL_SEND)

export const validateOtpResource = createSender(VALIDATE_OTP)

<template lang="pug">
include /mixins
div
  .vm-modal-wrap
    +b.H3.vm-modal-title(
      v-if="title",
    ) {{ title }}
    +b.H3.ds-caption.--size_3lg.--fw_bold.--color_boldblue.--appearance_center(
      v-if="messageTitle",
    ) {{ messageTitle }}
    +b.P.vm-modal-subtitl.ds-caption--appearance_center(
      v-if="subtitle",
    ) {{ subtitle }}
    +b.P.vm-modal-subtitle.ds-caption--appearance_center(
      v-if="messageSubtitle",
    ) {{ messageSubtitle }}
    component(
      v-bind="$attrs",
      @finish="finish",
      @close="$emit('close')",
      :is="isComponent",
      :initial="$options.clone(initial)",
    )
  button.vm-modal-close-button(
    type="button",
    :aria-label="_('Close')",
    @click="$emit('close')",
  ): span(aria-hidden="true") &times;
</template>
<script>
import { clone } from 'ramda';

export default {
  clone,
  props: {
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
    },
    messageTitle: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    messageSubtitle: {
      type: String,
    },
    promise: Function,
    isComponent: {},
  },
  methods: {
    finish(res) {
      // eslint-disable-next-line no-unused-expressions
      if (res) this.promise && this.promise(res);

      this.$emit('close');
    },
  },
};
</script>

export const userDeviceOSDetector = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  const android = -1 < userAgent.indexOf('android')
  const iphone = -1 < userAgent.indexOf('iphone')
  const links = Array.from(document.getElementsByClassName('js-app-link'))
  const os = { android, iphone }
  Object.keys(os).forEach(s => {
    links.forEach(link => {
      if (os[s]) link.href = link.dataset[s]
    })
  })
}

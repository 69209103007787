import { withDefaultProps } from '@aspectus/vue-utils';

import BaseView from '@app/List/View.vue';
import Controller from '@app/List/Controller.vue';
// import SimpleController from '@app/List/SimpleController.vue';

import {
  plansListUrl,
  plansListAdditionalUrl,
  attributesResource,
} from '@api/catalog.service';

const composer = (
  listUrl,
  filtersUrl,
  listController,
  needSorting = false,
  listAdditionalUrl
) => withDefaultProps({
  productsResource: () => listUrl,
  attributesResource: () => filtersUrl,
  // availableAttributesResource: () => availableFiltersUrl,
  listController: () => listController,
  needSorting,
  productsAdditionalResource: () => listAdditionalUrl,
})(BaseView);

const CatalogView = composer(
  plansListUrl,
  attributesResource,
  // availableAttributesResource,
  Controller,
  true,
  plansListAdditionalUrl
);

// const NewsView = composer(
//   productsListUrl,
//   attributesResource,
//   availableAttributesResource,
//   SimpleController,
//   false
// );

export default function plugin(Vue, { postfix = '-filters' } = {}) {
  Vue.component(`plan${postfix}`, CatalogView);
  // Vue.component(`news${postfix}`, NewsView);
  // Vue.component('search-filter', SearchFilter)
  // Vue.component('slim-filter', SlimFilter)
}

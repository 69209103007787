import store from './js/store';
import router from './js/router';

import { createI18n } from '@/i18n';

const CURRENCY_RULE = 'currency';

export function createApp({ Vue, I18n }) {
  const i18n = createI18n({ Vue, I18n });
  Vue.prototype.$body = document.body;
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      };
    },
  });
  function getCurrentDecimal(from, rule = CURRENCY_RULE) {
    let price = 0;
    try {
      price = Number(from.replace(',', '.'));
    } catch (e) {
      price = from;
    }
    return app.$n(price, rule);
  }
  // Usage:
  // pug - p!=vexp('getCurrentDecimal("'+exp('price')+'")')
  // vue template-  {{ getCurrentDecimal(cart.total_price_without_site_discount) }}
  // vue script-  this.getCurrentDecimal(cart.total_price_without_site_discount)
  Vue.prototype.getCurrentDecimal = getCurrentDecimal;
  return { app, i18n, store, router };
}

<template lang="pug">
include /mixins
catalog-sidebar(
  space="xl",
  :filters="filters",
  @update:filters="event => { filtersChenged = true; changeFilters(event) }"
  @update:parameters="event => { filtersChenged = true; }"
  :pagination="pagination",
  :attributes-resource="attributesResource",
  :need-sorting="needSorting",
  :sortlist="sortlist",
  :need-search="needSearch",
  :label="label"
  v-bind="$attrs"
)
  template(#title)
    slot(name="title")

  template(#content="")
    catalog-content(
      :is-filtered="filtersChenged"
      :loading="loading",
      :items="result && result.data",
      :pagination="pagination",
      @update:pagination="parameters => { changePagination(parameters); filtersChenged = !parameters.infinite }"
    )
      slot
</template>

<script>
import CatalogSidebar from './Sidebar.vue';
import CatalogContent from './Content.vue';

export default {
  name: 'CatalogController',
  components: { CatalogSidebar, CatalogContent },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'needFilters',
    'needSorting',
    'needSearch',
    'receiveAdditional',
    'scrollPaginate',
    'label',
    'sortlist',
  ],
  data() {
    return {
      filtersChenged: false,
    };
  },
  created() {
    this.search();
  },

  methods: {
    search() {
      console.log(this.parameters.filters);
      if (
        this.parameters.filters.agreement &&
        this.parameters.filters.agreement.length
      ) {
        this.receiveAdditional(this.parameters);
      } else {
        this.receive(this.parameters);
      }
    },
  },
};
</script>

import Header from './Header';
import Footer from './Footer';
import PageLayout from './PageLayout';

function install(Vue) {
  Vue.component('HeaderBlock', Header);
  Vue.component('FooterBlock', Footer);
  Vue.component('PageLayout', PageLayout);
}

export default { install };

import { createHOCc } from 'vue-hoc';

import flatPickr from 'vue-flatpickr-component';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { Ukrainian } from 'flatpickr/dist/l10n/uk.js';

const LOCALE_MAP = {
  ru: Russian,
  uk: Ukrainian,
};
const DEFAULT_LANG = LOCALE_MAP[window.language];

export const datepickerDefaults = createHOCc(
  {
    props: {
      config: {
        default: () => ({}),
      },
    },
  },
  {
    props: {
      config() {
        return Object.assign(this.config || {}, {
          locale: DEFAULT_LANG,
          dateFormat: this.config.dateFormat || 'd.m.Y',
          disableMobile: false,
        });
      },
    },
  }
);
export default datepickerDefaults(flatPickr);

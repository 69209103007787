<template lang="pug">
  include /mixins
  nav.bread-crumbs(
    aria-label="breadcrumb"
  )
    +b.bread-crumbs.UL
      +e.item.LI(
        v-for='(level, index) in crumbs'
      )
        +e.link.--active.A(
          v-if='level.text && index + 1 != crumbs.length'
          :href="level.to",
        )
          span {{ level.text }}
        +e.slash.SPAN.mdi.mdi-chevron-right(
          v-if='index + 1 == crumbs.length'
        ) /
        +e.link--last(
          itemprop="item"
          v-if='index + 1 == crumbs.length'
          )
          span(
            itemprop="name"
          ) {{ level.text }}

</template>

<script>

export default {
  name: 'BreadCrumbs',
  data() {
    return {
      levels: [
        {
          href: window.location.origin,
          title: this._('TOTIS'),
        },
        {
          title: this._('Личный кабинет'),
        },
      ],
    }
  },
  computed: {
    crumbs() {
      const pathArray = this.$route.path.split('/')
      pathArray.shift()
      // eslint-disable-next-line array-callback-return,consistent-return
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (this.$route.matched[idx]) {
          breadcrumbArray.push({
            path,
            to: breadcrumbArray[idx - 1]
              ? `/${ breadcrumbArray[idx - 1].path }/${ path}`
              : `/${ path}`,
            text: this.$route.matched[idx].meta.breadCrumb(this) || path,
          });
          return breadcrumbArray;
        }
      }, [])
      return breadcrumbs;
    },
  },
}
</script>

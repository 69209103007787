const messageGetter = x => x.message

export function defaultValidatorErrorsParser(
  errors,
  initKey,
  accumulator = {}
) {
  return Object.keys(errors).reduce((acc, x) => {
    const key = initKey ? `${initKey}:${x}` : x
    if (Array.isArray(errors[x])) {
      if (Object.prototype.hasOwnProperty.call(errors[x][0], 'message')) {
        acc[key] = errors[x].map(messageGetter)
        return acc
      }
      for (let i = 0, n = errors[x].length; i < n; i++) {
        defaultValidatorErrorsParser(errors[x][i], `${key}:${i}`, acc)
      }
      return acc
    }
    if ('object' !== typeof errors[x]) return acc
    return defaultValidatorErrorsParser(errors[x], key, acc)
  }, accumulator)
}

export default {
  data() {
    return {
      data: {},
      loading: false,
      nonFieldErrors: '',
    }
  },
  mounted() {
    // if (this.$refs.validator && this.$refs.validator.$el) {
    //   this.$refs.validator.$el.addEventListener(
    //     'blur',
    //     () => {
    //       if (this.$refs.validator) this.$refs.validator.reset()
    //       this.nonFieldErrors = ''
    //     },
    //     true
    //   )
    // }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400;
        const serverError = 500;
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e;
        }

        if (!this.updateValidator) {
          return e;
        }

        return e.json().then(body => {
          const errors = {};

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              if (403 === e.status) {
                Object.assign(errors, { forbidden: [error.state] });
              } else {
                Object.assign(errors, error.state);
              }
            }
          });

          this.updateValidator(errors);

          return e;
        });
      });
    },
    submit(valid, data) {
      if (!valid) {
        return Promise.reject()
      }

      this.loading = true
      return this.catchFormErrors(this.send(data)).finally(() => {
        this.loading = false
      })
    },
    scrollToFirstError(errors) {
      let first
      Object.entries(errors).some(([key, value]) => {
        if (value && value.length) first = key
        return value && value.length
      })

      if (
        first &&
        this.$refs.validator.refs[first] &&
        this.$refs.validator.refs[first].$el
      ) {
        this.$refs.validator.refs[first].$el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
    async updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.nonFieldErrors
      this.$refs.validator.setErrors(parsed)
      // this.scrollToFirstError(errors)
    },
  },
}
